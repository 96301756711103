import React, { useEffect, useState } from 'react'
import { Link, useNavigate, } from 'react-router-dom'
import {faBars } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';

function NavBarPanel({ishome}) {
	let history = useNavigate();
    const liveCounter = useSelector(state => state.buyerAuth?.liveCounter);

	library.add(faBars);

	const handleMatchClick = (route) =>{
		history(route);
    }

	const fetchCounter = (key) =>{
		if(liveCounter?.[key]){
			return liveCounter?.[key];
		} else {
			return 0;
		}
	}
	return (
		<div className="headerBottom">
			<ul className="newNav-scroll">
				{/* <li onClick={()=>{
				handleMatchClick()}} className='menuicon'>
					<img src='/assets/images/menu/home.png'/>
					<span to="/">Home</span>
				</li> */}
				<li onClick={()=>{
					handleMatchClick('/')
				}} className='menuicon'>
					<img src='/assets/images/menu/play.png'/>
					<span style={{color:'#FFFFFF'}}>In-Play</span>
				</li>
				<li onClick={()=>{
					handleMatchClick("/game/4")
				}} className='menuicon'>
					<div id="tagLive" className="tag-live"><strong>LIVE </strong><span>{fetchCounter('cricket')}</span></div>
					<img src='/assets/images/menu/cricket.png'/>
					<span className='awesome menu-title' style={{color:'#FFFFFF'}}>Cricket</span>
				</li>
				<li onClick={()=>{
					handleMatchClick("/game/1")
				}} className='menuicon'>
					<div id="tagLive" className="tag-live"><strong>LIVE </strong><span>{fetchCounter('soccer')}</span></div>
					<img src='/assets/images/menu/soccer.png'/>
					<span className='awesome menu-title' style={{color:'#FFFFFF'}}>Soccer</span>
				</li>
				<li onClick={()=>{
					handleMatchClick("/game/2")
				}} className='menuicon'>
					<img src='/assets/images/menu/tennis.png'/>
					<div id="tagLive" className="tag-live"><strong>LIVE </strong><span>{fetchCounter('tennis')}</span></div>
					<span className='awesome menu-title' style={{color:'#FFFFFF'}}>Tennis</span>
				</li>
				<li onClick={()=>{
					if(!ishome){
						handleMatchClick("/livecasino")
					} else {
						let el = document.getElementById('liveCasino')
						el.scrollIntoView({block: 'start', behavior: 'smooth'});
					}
				}} className='menuicon'>
					<img src='/assets/images/menu/chip.png'/>
					<span className='menu-title' style={{color:'#FFFFFF'}}>Live Casino</span>
				</li>
				<li onClick={()=>{
					if(!ishome){
						handleMatchClick("/virtualcasino")
					} else {
						let el = document.getElementById('topGames')
						el.scrollIntoView({block: 'start', behavior: 'smooth'});
					}
				}} className='menuicon'>
					<img src='/assets/images/menu/poker.png'/>
					<span className='menu-title' style={{color:'#FFFFFF'}}>Virtual Casino</span>
				</li>
				<li onClick={()=>{
					handleMatchClick('/')
				}} className='menuicon'>
					<img src='/assets/images/menu/cricket.png'/>
					<span className='menu-title' style={{color:'#FFFFFF'}}>Indian Poker</span>
				</li>
				
				<li onClick={()=>{
					handleMatchClick('/')
				}} className='menuicon'>
					<img src='/assets/images/menu/cricket.png'/>
					<span className='menu-title' style={{color:'#FFFFFF'}}>Sports Book</span>
				</li>
				
			</ul>
		</div>
	);

}


export default NavBarPanel;
