import React from 'react'

function Worliinstant({suspended=true,onCLick=()=>{}}) {

    
    const handleOnCLick = ({rate,bat_type,bat_multiply=1}) =>{
        if(!suspended){
            let batitem = {
                bat_rate:rate,
                bat_type:bat_type,
                sid:bat_type,
                bat_multiply:bat_multiply
            }
            onCLick(batitem);
        }
    }

    const list1 = [1,2,3,4,5];
    const list2 = [6,7,8,9,0];
    return (
        
        <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>

            <div className="topnewBet-bottomBox">
                <div className="row" style={{paddingLeft:'10px'}}>
                    {list1?.map((v,i)=>(
                        <div className="col-lg-1 col-2">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                handleOnCLick({rate:9,bat_type:`number_${v}`})
                            }}>
                                <h6 className="innerNewBat-box"></h6>
                                <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{height:'56px'}}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    {v}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-2">
                        <div className="innerNewBat-box pageClass" onClick={()=>{
                            handleOnCLick({rate:1.8,bat_type:'line1',bat_multiply:5})
                        }}>
                            <h6 className="innerNewBat-box"></h6>
                            <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{flexDirection:'column',height:'56px'}}>
                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                <div>Line 1 </div> <br/>
                                <div>1 | 2 | 3 | 4 | 5</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="innerNewBat-box pageClass" onClick={()=>{
                            handleOnCLick({rate:1.8,bat_type:`odd`,bat_multiply:5})
                        }}>
                            <h6 className="innerNewBat-box"></h6>
                            <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{flexDirection:'column',height:'56px'}}>
                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                <div>ODD </div> <br/>
                                <div>1 | 3 | 5 | 7 | 9</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingLeft:'10px'}}>
                    {list2?.map((v,i)=>(
                        <div className="col-lg-1 col-2">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                handleOnCLick({rate:9,bat_type:`number_${v}`})
                            }}>
                                <h6 className="innerNewBat-box"></h6>
                                <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{height:'56px'}}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    {v}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-2">
                        <div className="innerNewBat-box pageClass" onClick={()=>{
                            handleOnCLick({rate:1.8,bat_type:`line2`,bat_multiply:5})
                        }}>
                            <h6 className="innerNewBat-box"></h6>
                            <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{flexDirection:'column',height:'56px'}}>
                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                <div>Line 2 </div> <br/>
                                <div>6 | 7 | 8 | 9 | 0</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="innerNewBat-box pageClass" onClick={()=>{
                            handleOnCLick({rate:1.8,bat_type:`even`,bat_multiply:5})
                        }}>
                            <h6 className="innerNewBat-box"></h6>
                            <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`} style={{flexDirection:'column',height:'56px'}}>
                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                <div>EVEN </div> <br/>
                                <div>0 | 2 | 4 | 6 | 8</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Worliinstant;