import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import Global from '../../../config/global';
import MobileMenu from './mobileMenu';
import { GET } from 'config/api';


function Footer(props) {
	
	
	const [state, setState] = useState({
		sitedata: {}
	});
	useEffect(() => {
		fetchFooterData();
	}, [],);

	const fetchFooterData = async () => {

		GET(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
                console.log('data=========',data);
				stateHandler("sitedata", data?.sitedata);
			}

		})
	}

	const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}


	return (
		<footer className="footer-wrapper">
            <div className="footer-top">
                <div className="container">
                    <div className="footLogo">
                        <a href="index.html" title="Vasudev"><img src={`${Global.LOGO_PATH}${state?.sitedata?.footer_logo}`} alt="Vasudev" /></a>
                    </div>
                    {/* <p>{state?.sitedata?.footer_text} </p> */}

                    <ul className="footLogo-section">
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo1.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo2.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo3.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo4.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo5.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo6.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo7.png" alt="Vasudev" /></div></li>
                        <li><div className="footLogo-wrapper"><img src="/assets/images/footLogo8.png" alt="Vasudev" /></div></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="copyright">Copyright &copy; <script type="text/javascript">document.write(new Date().getFullYear());</script> Vasudev. All rights reserved.</div>
                </div>
            </div>
        </footer>
	);

}

export default Footer;
