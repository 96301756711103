import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import color from 'config/color';
import { POST, GET } from 'config/api';
import { toast } from 'react-toastify';
import global from '../../../../config/global';
import { updateUserProfile } from 'config/helper';
import { useLoader } from 'component/loader/loader';





function UpdateProfile() {
    const { showLoader } = useLoader();
    const { buyerdata} = useSelector((state) => state.buyerAuth);
    const [state, setState] = useState({
        id: buyerdata.id,
        name: buyerdata.name,
        user_name: buyerdata.user_name,
        mobile: buyerdata.mobile,
        address: buyerdata.address,
        email: buyerdata.email
    });
    library.add(faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);


    const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
    }
    
    const saveUserInfo = () => {
        if (validation()) {
            showLoader(true);
            let body = {
                id: state.id,
                name: state.name,
                mobile: state.mobile,
                address: state.address,
            }

            POST(`${global.W_BASEURL}customer/auth/saveCustomerInfo`, body).then((res) => {
                showLoader(false);
                if (res.data.status == 200) {
                    if (state.id) {
                        let updateData = {
                            name: state.name,
                            mobile: state.mobile,
                            address: state.address,
                        }
                        updateUserProfile(updateData);
                        toast.info("User updated !", { autoClose: 2000 });
                        //history.goBack();

                    }

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const validation = () => {
        const { name, address, mobile } = state;

        if (name == '') {
            toast.info("Name Cannot Be Blank !", { autoClose: 2000 });
        }
        else if (mobile == '') {
            toast.info("Mobile Cannot Be Blank !", { autoClose: 2000 });
        }
        else if (address == '') {
            toast.info("Address Cannot Be Blank !", { autoClose: 2000 });
        }
        else {
            return true;
        }

        return false;
    }
    

    return (
        <div className="col-lg-10">
            <div className='pageRight-mainWrapper'>
                <div className="category-title">
                    <h2>Update <span>Profile</span></h2>
                </div>

                <div className="row">
                    <div className="col-md-12" style={{ "padding": "10px" }}>
                        <div className="row" style={{ "margin": "10px" }}>
                            <div className="col-md-6">
                                <label style={{ color: color.txtYellow }}>Name</label>
                                <input onChange={({ target }) => { stateHandler('name', target.value) }} value={state.name} type="text" className="form-control" />
                            </div>
                            <div className="col-md-6">
                                <label style={{ color: color.txtYellow }}>User Name</label>
                                <input readOnly type="text"  value={state.user_name} className="form-control" />
                            </div>
                        </div>
                        <div className="row" style={{ "margin": "10px" }}>
                            <div className="col-md-6">
                                <label style={{ color: color.txtYellow }}>Email</label>
                                <input type="text" onChange={({ target }) => { stateHandler('email', target.value) }} value={state.email} className="form-control" />
                            </div>
                            <div className="col-md-6">
                                <label style={{ color: color.txtYellow }}>Mobile</label>
                                <input maxLength={10} onChange={({ target }) => {
                                    const re = /^[0-9\b]+$/;
                                    if (target.value === '' || re.test(target.value)) {
                                        stateHandler('mobile', target.value)
                                    }
                                }} value={state.mobile} type="text" className="form-control" />
                            </div>

                        </div>
                        <div className="row" style={{ "margin": "10px" }}>
                            <div className="col-md-12">
                                <label style={{ color: color.txtYellow }}>Address</label>
                                <textarea onChange={({ target }) => { stateHandler('address', target.value) }} value={state.address} className="form-control" />
                            </div>
                            {/* <div className="col-md-6" style={{ height: '100px', marginBottom: '40px' }}>
                                <label style={{ color: color.txtYellow }}>Profile</label>
                                <input style={{
                                    position: 'absolute',
                                    marginLeft: "0px",
                                    marginTop: '10px',
                                }} type="file" onChange={({ target }) => { getBase64(target) }} className="" />
                            </div> */}
                        </div>
                        <div className="row link-btn mt-3" style={{ "margin": "10px" }}>
                            <div className="col-md-12">
                                <Link onClick={() => {
                                    saveUserInfo()
                                }} ><i className="fa-solid fa-floppy-disk mr10" /> Update Profile</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );

}
export default UpdateProfile;