import WebRoute from './component/web/WebRoute';
import {Provider} from 'react-redux';
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom'
import DashboardHoc from 'component/web/hoc/dashboardHoc';
import { LoaderProvider } from 'component/loader/loader';
import { useEffect } from 'react';
import firebaseNotification from 'config/firebase';


function App() {
    useEffect(() => {
      firebaseNotification.initializeSW()
      // const handleKeyDown = (event) => {
      //   if (event.key === 'F12' || (event.ctrlKey && event.shiftKey)) {
      //     event.preventDefault();
      //   }
      // };
  
      // // Add the event listener for keydown
      // window.addEventListener('keydown', handleKeyDown);
  
      // // Clean up the event listener on component unmount
      // return () => {
      //   window.removeEventListener('keydown', handleKeyDown);
      // };
  }, []);

  return (
    <div className="main-content-wrapper" onContextMenu={(e)=>e.preventDefault()}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <LoaderProvider>
                <DashboardHoc >
                  <WebRoute />
                </DashboardHoc>
              </LoaderProvider>
            </Router>
            <ToastContainer hideProgressBar position="top-right" />
          </PersistGate>
        </Provider>
    </div>
  );
}

export default App;
