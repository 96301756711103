import React, { useState, useEffect, useRef } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad, faL } from '@fortawesome/free-solid-svg-icons';
import { MatchList } from './../matchComponent/MatchList';
import Global from 'config/global';
import { GET } from 'config/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HomeSlider from 'component/web/includes/homeslider';
import TopGames from 'component/web/includes/topGames';
import { initSocket } from 'config/socket';
import { useSelector } from 'react-redux';


function DashBoard(props) {
    
    const {event_id} = useParams();
    const location = useLocation();

    const isGameRoute = location.pathname.startsWith('/game/');

    const buyerdata = useSelector(state => state.buyerAuth?.buyerdata);
    const device_id = useSelector(state => state.buyerAuth?.device_id);
    const isPageLoaded = useRef();
    
    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);
  
    const [state, setState] = useState({
        isLoading:false,
        headerBanner:[],
        activeEvents:[],
        allEvents:[]
      });
      
    const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
    }

    useEffect(()=>{
        connectSocket();
        fetchHomeSLider();
        fetchActiveEvents();
    },[location?.pathname])


    const connectSocket = () =>{
        if(isPageLoaded.current == undefined){
            isPageLoaded.current = true;
            const socketCLient = `client_${buyerdata?.id}_${device_id}`;
            initSocket(socketCLient);
        }
    }

	const fetchHomeSLider = async () => {
		GET(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("headerBanner", data.banner);
			}
		})
	}

    const fetchActiveEvents = async () => {
		GET(`${Global.W_BASEURL}customer/match/fetchActiveEvents`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
                stateHandler('allEvents',data);
                stateHandler('activeEvents',data);
			}
		})
	}
    
    return (
            <div className="col-lg-10">
                <HomeSlider List={state?.headerBanner} />
                <div className={isGameRoute ? '' : 'scrollable-events'}>
                    {state?.activeEvents?.map((v,i)=>(v?.event_id == event_id || !event_id) && <MatchList index={i} path key={`matchList${i}`} value={v}/>)}
                </div>

                {(!event_id) &&
                <div className='mt-1'>
                    <TopGames isDashboard={true} />
                </div>
                }
            </div>
    );

}
export default DashBoard;