import React from 'react'

function Testtp({dataList={},suspended=true,onCLick=()=>{}}) {
    
    const handleOnCLick = (data,type) =>{
        if(!suspended){
            let batitem = {
                bat_rate: data?.lrate,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.lsection,
            }
            if(type == 'tiger'){
                batitem.bat_rate = data?.trate;
                batitem.sid = data?.tsection;
            }  if(type == 'dragon'){
                batitem.bat_rate = data?.drate;
                batitem.sid = data?.dsectionid;
            } 
            onCLick(batitem);
        }
    }

    const trList = [
        {name:'Winner',data:dataList?.winner},
        {name:'Pair',data:dataList?.pair},
        {name:'Flush',data:dataList?.flush},
        {name:'Straight',data:dataList?.straight},
        {name:'Trio',data:dataList?.trio},
        {name:'Straight Flush',data:dataList?.straight_flush},
    ]

    return (
        
        <div className="mainListing-container pageClass">
                <div className="container">
                    <div className="casinoScreen-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="screen1-row screen2-game">
                                    <div className="screen1-col">
                                        <div className="row gx-2  align-items-center">
                                            <div className="col-lg-5 col-3">
                                                <h6></h6>
                                            </div>
                                            <div className="col-lg-7 col-9">
                                                <div className="row gx-1">
                                                    <div className="col-4 text-center">
                                                        <h6>Tiger</h6>
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        <h6>Lion</h6>
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        <h6>Dragon</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {trList?.map((v,i)=>{
                                        return (
                                            <div className="screen1-col">
                                                <div className="row gx-2 align-items-center">
                                                    <div className="col-lg-5 col-3" style={{display:'flex'}}>
                                                        <h6>{v?.name}</h6>
                                                    </div>
                                                    <div className="col-lg-7 col-9">
                                                        <div className="row gx-1">
                                                            <div className="col-4" onClick={()=>{handleOnCLick(v?.data,'tiger')}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.data?.trate} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" onClick={()=>{handleOnCLick(v?.data,'lion')}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.data?.lrate} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" onClick={()=>{handleOnCLick(v?.data,'dragon')}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.data?.drate} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

}
export default Testtp;