import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import RenderBatModal from './BatModal';



const FansyBat = (props) => {
    let marketOdds = (props?.marketOdds)?props?.marketOdds?.data:[];
    let match_id = props?.match_id;
    let series_id = props?.series_id;
    let liveOdds = (props?.marketOdds?.liveOdds)?props?.marketOdds?.liveOdds:{};

    const filterList = [
        {key:'All',value:['all']},
        // {key:'RUNS',value:['innings']},
        {key:'OVER',value:['over']},
        {key:'RUNS',value:['run bhav','run']},
        {key:'Wicket',value:['wkt',]},
        {key:'BOUNDARIES',value:['boundaries']},
    ]

    const [showBatModal,setshowBatModal] = useState(false);
    const {exposureData} = useSelector(state => state.buyerGlobal);
    const [modalItem,setmodalItem] = useState({});
    const [fansyType,setFansyType] = useState(['all']);

    const {
        batPlace=()=>{},
    } = props;


    return (
    <>
        {(marketOdds?.length > 0)&&
        <div className="liveGame-section pageClass">
                <div className="liveGame-titleBox pageClass">
                    <div className="siteTitle pageClass pb-0">
                        <h2>Fansy</h2>
                    </div>
                </div> 
                <div className='fansyFilter'>
                    {filterList?.map((v,i)=>{
                        return (
                            <div className={`${(v?.value?.join(',') == fansyType?.join(','))?'active':''}`} onClick={()=>{
                                setFansyType(v?.value)
                            }}>{v?.key}</div>
                        )
                    })}
                </div>
                <div className="priceTable-boxMain pageClass">

                <div className="matchList-grid pageClass">
                    <div className="row">
                        <div className="col-lg-8 col-8 align-self-center">
                            <div className="matchTitle-box pageClass">
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-4">
                            <div className="priceGroup-box fancyPrice-group pageClass">
                                <div className="row gx-2">
                                    <div className="col col-6">
                                        <div className="priceColum-row">
                                            <div className="priceColum-grid text-center" style={{fontWeight:'bold'}}>
                                                No
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-6">
                                        <div className="priceColum-grid text-center" style={{fontWeight:'bold'}}>
                                            Yes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                    {marketOdds?.map((value,index)=>{
                    let regex = new RegExp(`\\b(${fansyType?.join('|')})\\b`, 'i'); // 'i' for case-insensitive matching
                    return (regex.test(value?.team_name) || fansyType?.[0] == 'all') && 
                        <RenderFansy fansyData={exposureData?.fansy} index={index} value={value} onBetClick={(item)=>{
                                setmodalItem({
                                    ...item,
                                    match_id:match_id,
                                    series_id:series_id,
                                });
                                setshowBatModal(true);
                            }} />
                        }
                    )}
                </div>    
                 {(showBatModal)&&<RenderBatModal liveBhav={liveOdds} onPlace={batPlace} onClose={()=>{
                     setshowBatModal(false)
                 }} showBatModal={showBatModal} modalItem={modalItem}/>}
        </div>   
        }
    </>
    )
}




const memoCompare = (prevProps, nextProps) =>{
    let value = prevProps?.value;
    let nvalue = nextProps?.value;
    if((value?.gstatus != nvalue?.gstatus) || (nvalue?.isUpdated == true) || (prevProps?.fansyData?.[value?.sid] != nextProps?.fansyData?.[value?.sid])) {
        return false;
    } else {
        return true;
    }
}

const RenderFansy = memo(({value,fansyData={},onBetClick}) =>{
    const {buyerdata} = useSelector((state) => state.buyerAuth);
    
    const checkBatIsSuspended = () =>(value?.status == 'SUSPENDED' || value?.status == 'Ball Running' || (value?.user_lock && value?.user_lock?.indexOf(buyerdata?.id) != -1) || (value?.staff_lock && value?.staff_lock?.indexOf(buyerdata?.parent_id) != -1));

    const onPress = ({type,odds,size}) =>{
        if(!checkBatIsSuspended())
        {
            
            let content = {
                runnerName:value?.team_name,
                fansy_id:value?.sid,
                batRate:odds,
                batSize:size,
                battype:type,
                bat_format:'fansy', 
                min:value?.min,
                max:value?.max,
            }
            onBetClick(content)
        }
    }

let back_odds = (value?.back_odds && value?.back_odds?.length) ? value?.back_odds[0]:{}
let lay_odds = (value?.lay_odds && value?.lay_odds?.length) ? value?.lay_odds[0]:{};


let price = (price)=>(price?price:0);
let size = (price)=>(price?price:0);
return(
    <div className="matchList-grid pageClass">
        <div className="row">
            <div className="col-lg-8 col-8 align-self-center">
                <div className="matchTitle-box pageClass">
                    <a href="javascript:void(0);">{value?.team_name} <span style={{color:'red',fontSize:'13px'}}>{(fansyData?.[value?.sid]) && `-${Math.abs(fansyData[value?.sid])}`}</span> </a>
                    <div style={{backgroundColor:'#ffffff',color:'red',fontSize:'10px'}}>{value?.rem}</div>
                </div>
            </div>
            <div className="col-lg-4 col-4">
                <div className="priceGroup-box fancyPrice-group pageClass">
                    <div className="row gx-2">
                        {(checkBatIsSuspended())&&<div className="overlayText" style={{color:'red'}}>{value?.status}</div>}
                        <div onClick={()=>{
                                if(!checkBatIsSuspended() && lay_odds?.price > 0){onPress({type:'lay',odds:lay_odds?.price,size:lay_odds?.size})}
                            }} className="col col-6">
                            <div className="priceColum-row">
                                <div className="priceColum-grid">
                                    <a href="javascript:void(0);" className="PinkPrice">{price(lay_odds?.price)} <br/> {size(lay_odds?.size)}</a>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>{
                            if(!checkBatIsSuspended() && back_odds?.price > 0){onPress({type:'back',odds:back_odds?.price,size:back_odds?.size})}
                        }} className="col col-6">
                            <div className="priceColum-grid">
                                <a href="javascript:void(0);" className="bluePrice">{price(back_odds?.price)} <br /> {size(back_odds?.size)} </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-2 align-self-center">
                <div className="maxMin-price">Min {abbreviateNumber(value?.min)} <br/> MAX {abbreviateNumber(value?.max)} </div>
            </div> */}
        </div>
    </div>
)},memoCompare);

export default FansyBat

