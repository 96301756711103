import React from 'react'

function Queen({dataList={},suspended=true,onCLick=()=>{}}) {
    
    const handleOnCLick = (data,bat_type) =>{
        if(!suspended){
            let rate = (bat_type == 'lay')?data?.l1:data?.b1;
            let nation = (data?.nat)?data?.nat:data?.nation;

            let batitem = {
                bat_rate:rate,
                bat_type:(bat_type)?bat_type:nation,
                sid:data?.sid,
            }
            
            onCLick(batitem);
        }
    }

    const trList = [
        {name:'Total 0',data:dataList?.total_0},
        {name:'Total 1',data:dataList?.total_1},
        {name:'Total 2',data:dataList?.total_2},
        {name:'Total 3',data:dataList?.total_3},
    ]

    return (
        <div className="mainListing-container pageClass">
                <div className="container">
                    <div className="casinoScreen-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="screen1-row screen2-game">
                                    <div className="screen1-col">
                                        <div className="row gx-2  align-items-center">
                                            <div className="col-lg-6 col-4">
                                                <h6></h6>
                                            </div>
                                            <div className="col-lg-3 col-4 text-center">
                                                <h6>Back</h6>
                                            </div>
                                            <div className="col-lg-3 col-4 text-center">
                                                <h6>Lay</h6>
                                            </div>
                                        </div>
                                    </div>

                                    {trList?.map((v,i)=>{
                                        return (
                                            <div className="screen1-col">
                                                <div className="row gx-2 align-items-center">
                                                    <div className="col-lg-6 col-4" style={{display:'flex'}}>
                                                        <h6>{v?.name}</h6>
                                                    </div>
                                                    <div className="col-lg-3 col-4" onClick={()=>{handleOnCLick(v?.data,'back')}}>
                                                        <div className="screen1-betBox pageClass">
                                                            <a className='bluePrice'>{v?.data?.b1} 
                                                                {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-4" onClick={()=>{handleOnCLick(v?.data,'lay')}}>
                                                        <div className="screen1-betBox pageClass">
                                                            <a className='PinkPrice'>{v?.data?.l1} 
                                                                {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

}
export default Queen;