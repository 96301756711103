import CardSlider from "./cardSlider";

const MakeCard32 =({data,gtype})=>{
    let cardList = {playerA:{title:'Player 8',card:'',total:8},playerB:{title:'Player 9',card:'',total:9},playerC:{title:'Player 10',card:'',total:10},playerD:{title:'Player 11',card:'',total:11}};
    if(gtype == 'queen'){
        cardList = {playerA:{title:'Total 0',card:'',total:0},playerB:{title:'Total 1',card:'',total:1},playerC:{title:'Total 2',card:'',total:2},playerD:{title:'Total 3',card:'',total:3}};
    }
    if(data?.desc){
        
        const biggestKey = Object.keys({C1:data?.C1,C2:data?.C2,C3:data?.C3,C4:data?.C4}).reduce((maxKey, key) => 
            data[key] > data[maxKey] ? key : maxKey
          );
        
        // Return the result as an object
        const bigdata = { [biggestKey]: data[biggestKey] };


        const values = data?.desc.split(',');
        let groupCounter = 1;
        
        const getValue = (oldval,newVal) =>{
            let card = newVal?.slice(0, -2)
            if(card == 'J') {
                card = 11;
            } else if(card == 'Q') {
                card = 12;
            } else if(card == 'K') {
                card = 13;
            } else if(card == 'A') {
                card = 1;
            } 

            let item = {...oldval};
            
            item.card = (item?.card)?`${item?.card},${newVal}`:`${newVal}`;
            
            item.total = (item?.total)?item?.total + parseInt(card):parseInt(card)
            return item;
        }
    
        values.forEach((value) => {
            if(value != '1'){
                if (groupCounter === 1) {
                    cardList.playerA = getValue(cardList.playerA,value);
                } else if (groupCounter === 2) {
                    cardList.playerB = getValue(cardList.playerB,value);
                } else if (groupCounter === 3) {
                    cardList.playerC = getValue(cardList.playerC,value);
                } else if (groupCounter === 4) {
                    cardList.playerD = getValue(cardList.playerD,value);
                }
            }
            groupCounter = (groupCounter % 4) + 1; // Cycle through 1, 2, 3, 4
        });
        
        return (
            <div className='LiveCards' >
                <div>
                    {(cardList?.playerA?.card != '')&&
                        <div>
                            <div style={{color:(bigdata?.C1)?'#32e033':'#FFFFFF',margin:'5px 0px 5px 0px'}}>{cardList?.playerA?.title} : <span style={{color:'#D7D420'}}>{cardList?.playerA?.total}</span></div>
                            {cardList?.playerA?.card?.split(',')?.map((v,i)=>(
                                <img src={`/assets/images/casino/${v}.png`} className={'blank-flip-image loaded'} loading="lazy"/>
                            ))}
                        </div>
                    }
                    {(cardList?.playerB?.card != '')&&
                        <div>
                            <div style={{color:(bigdata?.C2)?'#32e033':'#FFFFFF',margin:'5px 0px 5px 0px'}}>{cardList?.playerB?.title} : <span style={{color:'#D7D420'}}>{cardList?.playerB?.total}</span></div>
                            {cardList?.playerB?.card?.split(',')?.map((v,i)=>(
                                <img src={`/assets/images/casino/${v}.png`} className={'blank-flip-image loaded'} loading="lazy"/>
                            ))}
                        </div>
                    }
                    {(cardList?.playerC?.card != '')&&
                        <div>
                            <div style={{color:(bigdata?.C3)?'#32e033':'#FFFFFF',margin:'5px 0px 5px 0px'}}>{cardList?.playerC?.title} : <span style={{color:'#D7D420'}}>{cardList?.playerC?.total}</span></div>
                            {cardList?.playerC?.card?.split(',')?.map((v,i)=>(
                                <img src={`/assets/images/casino/${v}.png`} className={'blank-flip-image loaded'} loading="lazy"/>
                            ))}
                        </div>
                    }
                    {(cardList?.playerD?.card != '')&&
                        <div>
                            <div style={{color:(bigdata?.C4)?'#32e033':'#FFFFFF',margin:'5px 0px 5px 0px'}}>{cardList?.playerD?.title} : <span style={{color:'#D7D420'}}>{cardList?.playerD?.total}</span></div>
                            {cardList?.playerD?.card?.split(',')?.map((v,i)=>(
                                <img src={`/assets/images/casino/${v}.png`} className={'blank-flip-image loaded'} loading="lazy"/>
                            ))}
                        </div>
                    }
                </div>
            </div>
        )
    }
    return null
}

const RenderLiveCards = ({t1data={},t3data={},liveCardKey={},bfCardList}) =>{
    let liveCards = (Object.keys(bfCardList)?.length)?bfCardList:t1data;
    let liveCardArray = [];
    let liveCardArrayB = [];

    if(t3data?.aall){
        liveCardArray = t3data?.aall?.split(',');
    }
    if(t3data?.ball){
        liveCardArrayB = t3data?.ball?.split(',');
    }

    let dealerCard = {card0:'blank',card1:'blank',card2:'blank'};
    if(liveCardKey?.dealer){
        let cardsList = t1data?.Cards?.split(',');
        if(cardsList){
            // Loop through the values and distribute them alternately
            for (let i = 0; i < cardsList.length; i++) {
                let card = cardsList[i];
                if(card != '1'){
                    if(i >= 3){
                        if (i % 2 === 0) {
                            liveCardArray.push(card);  // Even index (0, 2, 4, ...)
                        } else {
                            liveCardArrayB.push(card);  // Odd index (1, 3, 5, ...)
                        }
                    } else {
                        dealerCard[`card${i}`] = card;
                    }
                }
            }
        }
    }

    if(liveCardKey?.type == 'card32' || liveCardKey?.type == 'card32b' || liveCardKey?.type == 'queen'){
        return <MakeCard32 gtype={liveCardKey?.type} data={t1data} />
    }
    else if(liveCardKey?.dealer){
        return (
            <div className='LiveCards' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <div>
                            <div style={{color:'#FFFFFF',marginRight:'10px'}}>A</div>
                            <div style={{color:'#FFFFFF',marginRight:'10px'}}>B</div>
                        </div>
                        <div>
                            <img src={`/assets/images/casino/${dealerCard?.card0}.png`} className={(dealerCard?.card0 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1">
                            <img src={`/assets/images/casino/${dealerCard?.card2}.png`} className={(dealerCard?.card0 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                        
                        <div >
                            <img src={`/assets/images/casino/${dealerCard?.card1}.png`} className={(dealerCard?.card1 != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>
                        </div>
                    </div>
                </div>
                <div>
                    <CardSlider style={{marginBottom:'3px'}} data={liveCardArray}/>
                    <CardSlider style={{marginBottom:'3px'}} data={liveCardArrayB}/>
                </div>
            </div>
        )
    }
    else if(liveCardKey?.slider){
        return (
            <div className='LiveCards'>
                <CardSlider data={liveCardArray}/>
                <CardSlider data={liveCardArrayB}/>
            </div>
        )
    }else {
        return (
            <div className='LiveCards' >
                {/* {(liveCardKey?.levelA)&& */}
                <>
                    <div style={{color:'#FFFFFF'}}>
                        {liveCardKey?.levelA}
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        {liveCardKey?.levelACards?.map((v,i)=>{
                            let card = (liveCards?.[v])?(liveCards?.[v] && liveCards?.[v] != '1')?liveCards?.[v]:'blank':'';
                            if(card){
                                return (<img src={`/assets/images/casino/${card}.png`} className={(card != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>)
                            }
                        })}
                    </div>
                </>
                {/* } */}
    
                {/* {(liveCardKey?.levelB)&& */}
                <>
                    <div style={{color:'#FFFFFF'}}>
                        {liveCardKey?.levelB}
                    </div>
                    <div>
                        {liveCardKey?.levelBCards?.map((v,i)=>{
                            let card = (liveCards?.[v])?(liveCards?.[v] && liveCards?.[v] != '1')?liveCards?.[v]:'blank':'';
                            if(card){
                                return (<img src={`/assets/images/casino/${card}.png`} className={(card != 'blank')?'flip-image loaded':'blank-flip-image loaded'} loading="lazy"/>)
                            }
                        })}
                    </div>
                    </>
                {/* } */}
            </div>
        )
    }
    
}

export default RenderLiveCards;
