import {BLOGIN,BLOGOUT} from './actionTypes';

const initialState = {
    buyerdata:{},
    siteConfig:{},
    matchData:'',
    device_id:'',
    liveCounter:{},
    STAKELIST:[]
};
 
export const BuyerAuthReducer = (state = initialState, action)=> {
    switch (action.type){
        case BLOGIN:
            return {
                ...state,buyerdata:action.payload
            }

        case 'MATCHDATA':
            return {
                ...state,matchData:action.payload
            }
        case 'SITECONFIG':
            return {
                ...state,siteConfig:action.payload
        }

        case 'STAKELIST':
            return {
                ...state,STAKELIST:action.payload
        }
        case 'LIVECOUNTER':
            return {
                ...state,liveCounter:Object.assign(initialState.liveCounter,action.payload)
        }
        case 'DEVICE_ID':
            return {
                ...state,device_id:action.payload
            }
        case BLOGOUT:
        return {
                ...state,buyerdata:{},matchData:'',STAKELIST:[]
        }           
        default:
            return state;
    }
}