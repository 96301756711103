
import React, { createContext, useContext, useState } from 'react';
require('../loader/loader.css');

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const showLoader = (val) => setLoading(val);

  return (
    <LoaderContext.Provider value={{ loading, showLoader }}>
      {children}
      {loading && <Loader />} {/* Render the loader here */}
    </LoaderContext.Provider>
  );
};

const Loader = () => (
  <div className='overlay'>
    <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
    </div>
  </div>
);

export const useLoader = () => {
  return useContext(LoaderContext);
};
