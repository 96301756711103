import {store} from './../redux/store';
import CryptoJS from 'crypto-js';
import Global from './global';

export const truncate = (str,length) => {
    if(str) {
        return str.length > length ? str.substring(0, length) + "..." : str;
    }
}



export const encrypt = (props) =>{
    return CryptoJS.AES.encrypt(JSON.stringify(props), Global.ENCRYPTION_KEY).toString();
}

export const decrypt = (string) =>{
    try{
        var bytes  = CryptoJS.AES.decrypt(string, Global.ENCRYPTION_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch(e){
        console.log(e,'////////////')
    }
}



function getDecimalPart(num) {
    if (Number.isInteger(num)) {
      return 0;
    }
  
    const decimalStr = num.toString().split('.')[1];
    return parseFloat(`0.${decimalStr}`);
}

const calculateExposure = (params) =>{
    if(params.bat_format == 'fansy') {
        return params.bat_stack;
    } else {
        let stack = parseFloat(params.bat_stack);
        if(params.bat_type == 'lay') {
            let rate = getDecimalPart(params.bat_rate);
            return rate * stack;
        } else {
            return stack;
        }
    }
}

export const updateUserWallet = (exposure_bal,type) =>{
    let state = store.getState();
    let {buyerdata} = state?.buyerAuth;
    let updateBuyerData = {...buyerdata};
    let wallet = updateBuyerData?.rel_wallet;

    if(!isNaN(wallet)){
        wallet = parseFloat(wallet);
        if(type == 'add') {
            // updateBuyerData.wallet = wallet + amount;
            // updateBuyerData.exposure_bal = exposure_bal - amount;
        } else {
            updateBuyerData.wallet = wallet - exposure_bal;
            updateBuyerData.exposure_bal = exposure_bal;
        }

        store.dispatch({
            type:'BLOGIN',
            payload:updateBuyerData
        });
    }
}
export const updateUserProfile = (params) =>{
    let state = store.getState();
    let {buyerdata} = state?.buyerAuth;
    let updateBuyerData = {...buyerdata};

    updateBuyerData.name =params.name ;
    updateBuyerData.mobile = params.mobile;
    updateBuyerData.address = params.address;


        store.dispatch({
            type:'BLOGIN',
            payload:updateBuyerData
        });
    
}
export const updateUserPassword = (params) =>{
    let state = store.getState();
    let {buyerdata} = state?.buyerAuth;
    let updateBuyerData = {...buyerdata};

    updateBuyerData.oldpass =params.newpassword ;
        store.dispatch({
            type:'BLOGIN',
            payload:updateBuyerData
        });
    
}


export const drawerOpenClose = () =>{
    var dashboard_sidebar = document.getElementById("dashboard-sidebar");
    var dashboard_right_content = document.getElementById("dashboard-right-content");
    dashboard_sidebar.classList.toggle("active");
    dashboard_right_content.classList.toggle("active");
}

export const checkisMobile = (length = 992) =>{
    const width  = window.screen.width;
    const isMobile = width < length;
    return isMobile
}

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
export const abbreviateNumber = (number) =>{

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}