import { POST } from 'config/api';
import global from 'config/global';
import React, { useState, useEffect, useRef } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import CasinoBatPlace from './component/casinobatplace';
import 'react-circular-progressbar/dist/styles.css';
import RenderCasinoTv from './component/RenderCasinoTv';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { disconnectSocket, getSocket, initSocket } from 'config/socket';
import RenderLiveCards from './component/RenderLiveCards';
import RenderCasinoResult from './component/RenderCasinoResult';
import componentList from './games/index';

function RenderCasino() {
    const {buyerdata,device_id} = useSelector(state => state.buyerAuth);
    const[batdata,setBatData]= useState({});
    const {casino_id} = useParams()
    const isPageLoaded = useRef();
    const [timer,setTimer] = useState(0);
    const timerRef = useRef(0);

   const [state,setState] = useState({
        dataList:{},
        t1data:{autotime:0},
        t3data:{},
        bfCardList:{},
        tv:''
   });
   
   const stateHandler = (key, value) => {
    state[key] = value;
    setState({ ...state });
  }

  let interval = '';
   useEffect(()=>{
        if(casino_id){
            fetchCasinoData(casino_id)

            return ()=>{
                if(interval) {
                    console.log('disconnect')
                    const socketCLient = `client_${buyerdata?.id}_${casino_id}_${device_id}`;
                    disconnectSocket(socketCLient);
                    clearInterval(interval);
                }
            }
        }
        
    },[casino_id]);


    const fetchCasinoData = (casino_id) =>{
        if(isPageLoaded.current == undefined) {
            isPageLoaded.current = true;
            // showLoader(true);

            // initial call
            const socketCLient = `client_${buyerdata?.id}_${casino_id}_${device_id}`;
            initSocket(socketCLient);
            
            let socket = getSocket();
            socket?.on('connected',()=>{
                
                socket?.emit('fetchcasino',{user_id:socketCLient,casino_id:casino_id});
                interval =  setInterval(() => {
                    if(timerRef?.current > 0){
                        timerRef.current = timerRef?.current - 1
                        setTimer(prev => prev - 1);
                    }
                    socket?.emit('fetchcasino',{user_id:socketCLient,casino_id:casino_id});
                }, 1000);
                

                socket?.on('sendCasino', (resp)=>{
                    
                    if(resp) {
                        let stateObj = {
                            dataList:{},
                            t1data:{autotime:0},
                            t3data:{},
                            bfCardList:{},
                            tv:''
                        }
                        
                        let data = JSON.parse(resp);
                        if(data?.t1?.length){
                            let t1 = data?.t1[0];
                            let autotime = (t1?.autotime)?parseInt(t1?.autotime):0

                            if(timerRef?.current <= 0 || timerRef?.current > autotime){

                                timerRef.current = autotime;
                                setTimer(autotime)
                            }
                            stateObj['t1data'] = t1;
                        }

                        if(data?.t3?.length){
                            let t3 = data?.t3[0];
                            stateObj['t3data'] = t3;
                        }


                        let dataList = {};
                        if(data?.t2?.length){
                            for(let item of data?.t2){
                                let nation = (item?.nat)?item?.nat:item?.nation
                                let keyName = nation?.replace(/[-\s]/g, '_')?.toLowerCase();
                                dataList[keyName] = item;
                            }
                        }
                        // for tp1 day
                        if(data?.bf?.length){
                            let lasttime = 0;
                            let index = 0;
                            let bfCardList = {};
                            for(let item of data?.bf){
                                if(index == 0){
                                    bfCardList['C1'] = item?.C1;
                                    bfCardList['C2'] = item?.C2;
                                    bfCardList['C3'] = item?.C3;
                                } else {
                                    bfCardList['C4'] = item?.C1;
                                    bfCardList['C5'] = item?.C2;
                                    bfCardList['C6'] = item?.C3;
                                }
                                index++;

                                let nation = (item?.nat)?item?.nat:item?.nation
                                let keyName = nation?.replace(/[-\s]/g, '_')?.toLowerCase();
                                dataList[keyName] = item;
                                lasttime = (item?.lasttime)?parseInt(item?.lasttime):0;
                            }
                            stateObj['bfCardList'] = bfCardList;
                            
                            if(timerRef?.current <= 0 || timerRef?.current > lasttime){
                                timerRef.current = lasttime;
                                setTimer(lasttime)
                            }
                        }
                        stateObj['dataList'] = dataList;

                        if(state?.tv == ''){
                            stateObj['tv'] = data?.tv;
                            // showLoader(false);
                        }

                        setState(stateObj);
                    }
                });
            });
        }
    }
    

    const checkSuspended = ()=>(timer <= 5);

    const onCLick = (data) =>{
        if(checkSuspended() == false) {
            let batitem = {
                mid:(data?.mid)?data?.mid:state?.t1data?.mid,
                gtype:global?.CASINO_GAME_TYPE[casino_id],
                bat_rate:data?.bat_rate,
                bat_type:data?.bat_type,
                sid:data?.sid,
                bat_multiply:(data?.bat_multiply)?data?.bat_multiply:1,
            }

            setBatData(batitem);
        }
    }

    const renderTitle = ()=>{
        if(componentList[casino_id]?.title){
            return componentList[casino_id]?.title
        } else {
            return '';
        }
    }

    const renderComponent = ()=>{
        if(componentList[casino_id] && componentList[casino_id]?.component){
            let Component = componentList[casino_id]?.component;
            let title = componentList[casino_id]?.title;

            return <Component t1={state?.t1data} t3={state?.t3data} dataList={state?.dataList}  title={title} onCLick={onCLick} suspended={checkSuspended()} casino_id={casino_id} />
        }
        return null;
    }

    return (
        <div className="col-lg-10">
            <div className='row gx-0'>
                <div className='col-md-9'>
                    
                    <div className='iframe-conter-box'>
                        <RenderLiveCards {...state} liveCardKey={componentList[casino_id]?.liveCardKey} casino_id={casino_id}/>
                        <RenderCasinoTv tvUrl={state?.tv} title={renderTitle()} />
                        <div className='circalCounter-box' style={{height:'100px',width:'100px'}}>
                            <CircularProgressbar value={(timer * 100)/componentList[casino_id]?.timerEnd} text={`${timer}`} />
                        </div>
                    </div>
                    {(!checkSuspended() && batdata?.bat_rate > 0)&&
                        <CasinoBatPlace mode={'mobile'} onClose={()=>{setBatData({})}} t1data={state?.t1data} batdata={batdata} />
                    }
                    <div>
                        {renderComponent()}
                    </div>
                </div>
                <div className='col-md-3'>
                    <RenderCasinoResult timer={timer} casino_id={casino_id} />
                    {(!checkSuspended() && batdata?.bat_rate > 0)&& <CasinoBatPlace mode={'desktop'} onClose={()=>{setBatData({})}} t1data={state?.t1data} batdata={batdata} />}
                </div>
            </div>
    </div>
    );
}


export default RenderCasino;