import React, { useState, useEffect } from 'react'

import { POST } from 'config/api';
import { truncate } from 'config/helper';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import { subscribe, unsubscribe } from 'config/event';

function CurrentBatHistory({match_id,type}) {
    library.add(faCircle);
    const dispatch = useDispatch();
    const [activeTab,setActiveTab] = useState('all')
    const [historyList,setHistoryList] = useState([]);
    const [oldhistoryList,setoldHistoryList] = useState([]);

    useEffect(()=>{
        if(match_id) {
            getBattingHistory(match_id)
            
            subscribe('refreshBatHistory',(data)=>{
                getBattingHistory(match_id)
            });
    
            return ()=>{
                unsubscribe('refreshBatHistory');
            }

        }
    },[match_id])

    const getBattingHistory = (match_id) => {
        let body = {
            index:0,
            limit:10,
            match_id:match_id,
        }
        
        POST(`${global.W_BASEURL}customer/transaction/getBattingHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;

                /**fansy loss */
                let fansy_loss = {};
                
                for(let item of data?.list){
                    let loss = 0;
                    // if(item.bat_type == 'back') {
                    //     loss = -item?.profit;
                    // } else {
                        loss = item?.loss;
                    // }

                    if(fansy_loss[item?.fansy_id]){
                        fansy_loss[item?.fansy_id] = fansy_loss[item?.fansy_id] + loss;
                    } else {
                        
                        fansy_loss = Object.assign({[item?.fansy_id]:loss},fansy_loss);
                    }
                }

                
                if(Object.keys(fansy_loss)?.length) {
                    dispatch({
                        type:'EXP_FANSY',
                        payload:fansy_loss
                    });
                }
                
                setHistoryList(data?.list)
                setoldHistoryList(data?.list);
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const filterData = (type) =>{
        setActiveTab(type);
        let batFormat = (type == 'odds')?'normal':'fansy';
        let data = [...oldhistoryList];
        if(type != 'all') {
            data = data?.filter((v,i)=>(v.bat_format == batFormat));
            setHistoryList(data);
        } else {
            setHistoryList(data);
        }
    }

    return (
        <div className="liveGame-section pageClass">
            <div className="liveGame-titleBox pageClass">
                <div className="siteTitle pageClass pb-0">
                    <h2>Match Bet</h2>
                </div>
            </div>
            <table className="table table-dark matchBet-table">
                <thead>
                    <tr>
                        <th>Match Bat</th>
                        <th>Bat Type</th>
                        <th>Odds</th>
                        <th>Stack</th>
                    </tr>
                </thead>
                <tbody>
                    {historyList?.map((item,index)=>(
                        <tr style={{textAlign:'center'}} className={`${(item?.bat_type == 'back')?'backRow':'layRow'}`}>
                            <td>{item?.team_name} ({item?.bat_format})</td>
                            <td>{item?.bat_type}</td>
                            <td>{item?.bat_rate}</td>
                            <td>{item?.bat_stack}</td>
                        </tr>
                    ))}
                    
                    {(historyList?.length == 0)&&
                    <tr style={{textAlign:'center'}}>
                        <td colSpan="4">No Record Found!</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div> 
    );

}
export default CurrentBatHistory;