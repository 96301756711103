import React from 'react'

function AndarBahar({dataList={},t3,suspended=true,onCLick=()=>{}}) {


    const andarList = {'1':'A','2':'2','3':'3','4':'4','5':'5','6':'6','7':'7','8':'8','9':'9','10':'10','11':'j','12':'q','13':'k'};
    const baharList = {'21':'A','22':'2','23':'3','24':'4','25':'5','26':'6','27':'7','28':'8','29':'9','30':'10','31':'j','32':'q','33':'k'};

    const andarResultListing = (t3?.ar)?t3?.ar?.split(','):[];
    const baharResultListing = (t3?.br)?t3?.br?.split(','):[];

    const handleOnCLick = (data) =>{
        if(!suspended){
            let batitem = {
                bat_rate:data?.rate,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    return (
        
        <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>
            <div className="topnewBet-bottomBox">
                <div>
                    <div className="col-lg-12" style={{padding:'5px'}}>
                        <div className="twoColum-box">
                            <h5>Andar</h5>
                            <ul className="twoColum-groupList" style={{listStyle:'none',display:'flex'}}>
                                {Object.keys(andarList)?.map((v,i)=>{
                                    if(andarResultListing?.length){
                                        let card = 'blank';
                                        let cardIndex = andarResultListing?.indexOf(v);
                                        if(cardIndex != -1 && andarResultListing[cardIndex] != '0'){
                                            cardIndex = cardIndex+1;
                                            card = andarList[cardIndex]
                                        }
                                        return ( <li><img src={`/assets/images/casino/${card}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>    )
                                    }else {
                                        let value = andarList[v];
                                        return ( <li onClick={()=>{
                                            handleOnCLick(dataList[`ander_${value}`])
                                        }}><img src={`/assets/images/casino/${value}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>    )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-12" style={{padding:'5px'}}>
                        <div className="twoColum-box">
                            <h5>Bahar</h5>
                            <ul className="twoColum-groupList" style={{listStyle:'none',display:'flex'}}>
                                {Object.keys(baharList)?.map((v,i)=>{
                                    if(baharResultListing?.length > 0){
                                        let card = 'blank';
                                        let cardIndex = baharResultListing?.indexOf(v);
                                        if(cardIndex != -1 && baharResultListing[cardIndex] != '0'){
                                            cardIndex = 20 + cardIndex + 1;
                                            card = baharList[cardIndex]
                                        }

                                        return ( <li><img src={`/assets/images/casino/${card}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>    )
                                    }else {
                                        let value = baharList[v];
                                        return ( <li onClick={()=>{
                                            handleOnCLick(dataList[`ander_${value}`])
                                        }}><img src={`/assets/images/casino/${value}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>    )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default AndarBahar;