import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import Accordion from 'react-bootstrap/Accordion';
import { POST } from 'config/api';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import Header from 'component/web/includes/header';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

function Withdrawal(props) {
    library.add(faCircle);
    const [AccountList,setAccountList] = useState([]);
    const [withdrawalHistory,setwithdrawalHistory] = useState([]);
    const {buyerdata} = useSelector(state => state?.buyerAuth);

    const [state,setState] = useState({
        accountname:'',
        accountnumber:'',
        ifsccode:'',
        mobile:'',
        password:'',
        upi_id:'',
        upi_platform:'',
        account_type:'bank',
        withdrawal_amount:'',
        account_id:'',
        addAccountModal:false,
    });
    
    useEffect(()=>{
        getBankAccountList();
        getWithdrawalHistory();
    },[])
	
    const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}

    const getBankAccountList = () => {
        let body = {
            index:0,
            limit:10,
        }

        POST(`${global.W_BASEURL}customer/transaction/getBankAccountList`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setAccountList(data)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const getWithdrawalHistory = () => {
        let body = {
            index:0,
            limit:10,
        }

        POST(`${global.W_BASEURL}customer/transaction/getWithdrawalHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setwithdrawalHistory(data)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    

    const withdrawRequest = () => {

        if(state?.account_id ==''){
            toast("Please Select Bank Account");

        } else if(state?.withdrawal_amount ==''){
            toast("Please Enter Amount");
        } else {
            let body = {
                withdrawal_amount:state?.withdrawal_amount,
                bank_account_id:state?.account_id,
                parent_id:buyerdata?.parent_id,
            }
            
            
            POST(`${global.W_BASEURL}customer/transaction/withdrawRequest`,body).then((res)=>{
                if(res.data.status == 200) {
                    emptyState();
                    toast("Withdrawal Request Submited");
                } 
                if(res.data.status == 500) {
                    let msg = res?.data?.msg
                    if(msg){
                        emptyState();
                        toast(msg);
                    }
                }
            }).catch((err)=>{
                console.log(err,'/////');
            })
        }
        
    }

    const emptyState = () =>{
        setState({
            accountname:'',
            accountnumber:'',
            ifsccode:'',
            mobile:'',
            password:'',
            upi_id:'',
            upi_platform:'',
            account_type:'bank',
            withdrawal_amount:'',
            account_id:''
        })
    }

    const validation = () =>{
        const {accountname,accountnumber,ifsccode,account_type,mobile,password,upi_id,upi_platform} = state;

        if(accountname == '' ) {
            toast("Please Enter Account Name");
            return false;
        }
        if(account_type == 'bank'){
            if(accountnumber == '') {
                toast("Please Enter Account Number");
                return false;
            } 
            if(ifsccode == '') {
                toast("Please Enter IFSC Code");
                return false;
            }
        } else {
            if(upi_platform == '') {
                toast("Please Select Upi Platform");
                return false;
            }else if(upi_id == '') {
                toast("Please Enter Upi Id");
                return false;
            }
        }

        if(mobile == '') {
            toast("Please Enter Mobile");
            return false;
        }else if(password == '') {
            toast("Please Enter Password");
            return false;
        }
        else {
            return true;   
        }
    }

    const addBankAccount = () => {
        if(validation()){
            let body = {
                account_name:state?.accountname,
                account_number:state?.accountnumber,
                ifsc_code:state?.ifsccode,
                mobile:state?.mobile,
                password:state?.password,
                account_type:state?.account_type,
                upi_platform:state?.upi_platform,
                upi_id:state?.upi_id,
            }
            
            POST(`${global.W_BASEURL}customer/transaction/addBankAccount`,body).then((res)=>{
                if(res.data.status == 200) {
                    emptyState();
                    getBankAccountList()
                    toast("Bank Successfully Added");
                }
            }).catch((err)=>{
                console.log(err,'/////');
            })
        }
        
    }

    return (
        <div className="col-lg-10">
            <div className="depositsWrapper">
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <h2>Withdrawal</h2> 
                    <button className='btn btn-info' onClick={()=>{
                        stateHandler('addAccountModal',true);
                    }} style={{backgroundColor:'#0094a4',color:'#FFFFFF'}}>Add Account</button>                               
                </div>
                <div className="homeWrapper-box pageClass">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="siteTitle pageClass pb-0">
                                        <h4>Withdrawal Request</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="depositAmt-form pageClass">
                                <div className="row">
                                    <div className='col-md-4' style={{marginBottom:'15px'}}>
                                        <label className="control-label">Select Bank Account</label>
                                        <select value={state?.account_id} style={{color:'#000000'}} className="form-control" onChange={({target})=>{
                                            stateHandler('account_id',target?.value)
                                        }}>
                                            <option value={''}>Select Bank Account</option>
                                            {AccountList?.map((v,i)=>(
                                                <option value={v?.id}>
                                                {(v?.account_type == `bank`)?
                                                    <div className=''>
                                                            Account Name : {v?.account_name} ||
                                                            Account Number : {v?.account_number}
                                                    </div>
                                                :
                                                    <div className='boxAc-viewData'>
                                                        Upi Id: {v?.upi_id} ||
                                                        Upi platform : {v?.upi_platform}
                                                        Mobile : {v?.mobile}
                                                    </div>
                                                }
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter Amount</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Amount" onChange={({target}) => {
                                                 const re = /^[0-9\b]+$/;
                                                if (target.value === '' || re.test(target.value)) {
                                                    stateHandler('withdrawal_amount',target?.value)
                                                }
                                            }} value={state?.withdrawal_amount} />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">&nbsp;</label>
                                            <button onClick={()=>{
                                                withdrawRequest()
                                            }} type="submit" className="btn btn-primary" style={{marginTop:'5px',border:'0px',backgroundColor:'#0094a4'}}><i className="fa-solid fa-right-to-bracket"></i> Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <Modal show={state?.addAccountModal} dialogClassName="loginModal-box modal-dialog modal-xl modal-dialog-centered"  onHide={()=>{
                    stateHandler('addAccountModal',false)
                }}>
                    <div className="modal-body" style={{backgroundImage:'none'}}>
                        <button type="button" onClick={()=>{
                            stateHandler('addAccountModal',false)
                        }} className="btn-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
                        <div className="homeWrapper-box pageClass">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="siteTitle pageClass pb-0">
                                        <h4>Add Bank Account</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="depositAmt-form pageClass">
                                <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div style={{marginRight:'10px'}}>
                                        <label htmlFor="bank" style={{marginRight:'5px',fontSize:'18px',color:'#FFFFFF'}}>Bank Account</label>
                                        <input checked={(state?.account_type == 'bank')} id={'bank'} type="radio" style={{color:'#000000',marginBottom:'4px'}} name='account_type' onChange={({target}) => {
                                            stateHandler('account_type','bank')
                                        }} />
                                    </div>
                                    <div style={{marginRight:'10px'}}>
                                        <label htmlFor="upi" style={{marginRight:'5px',fontSize:'18px',color:'#FFFFFF'}}>UPI</label>
                                        <input checked={(state?.account_type == 'upi')} id={'upi'} type="radio" style={{color:'#000000',marginBottom:'4px'}} name='account_type' onChange={({target}) => {
                                            stateHandler('account_type','upi')
                                        }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter Account Name</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Account Name" onChange={({target}) => {
                                                stateHandler('accountname',target?.value)
                                            }} value={state?.accountname} />
                                        </div>
                                    </div>
                                    {(state?.account_type == 'upi')&&
                                        <div className='col-md-6' style={{marginBottom:'15px'}}>
                                            <label className="control-label">Select Platform</label>
                                            <select value={state?.upi_platform} style={{color:'#000000'}} className="form-control" onChange={({target})=>{
                                                stateHandler('upi_platform',target?.value)
                                            }}>
                                                <option value={''}>Select Platform</option>
                                                <option value={'googlepay'}>Google Pay</option>
                                                <option value={'phonepay'}>Phone Pay</option>
                                                <option value={'paytm'}>Paytm</option>
                                                <option value={'payzap'}>Payzap</option>
                                            </select>
                                        </div>
                                    }
                                    {(state?.account_type == 'upi')&&
                                    <div className="col-md-6">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter UPI Id</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Upi Id" onChange={({target}) => {
                                                stateHandler('upi_id',target.value);
                                            }} value={state?.upi_id} />
                                        </div>
                                    </div>}

                                    {(state?.account_type == 'bank')&&
                                    <div className="col-md-6">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter Account Number</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Account Number" onChange={({target}) => {
                                                const re = /^[0-9\b]+$/;
                                                if (target.value === '' || re.test(target.value)) {
                                                stateHandler('accountnumber',target.value);
                                                }
                                            }} value={state?.accountnumber} />
                                        </div>
                                    </div>}
                                    {(state?.account_type == 'bank')&&
                                    <div className="col-md-6">                                                
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter IFSC Number</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter IFSC Number" onChange={({target}) => {
                                            stateHandler('ifsccode',target?.value)
                                            }} value={state?.ifsccode} />
                                        </div>                                                
                                    </div>}

                                    <div className="col-md-6">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter Mobile Number</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Mobile Number" onChange={({target}) => {
                                                const re = /^[0-9\b]+$/;
                                                    if (target.value === '' || re.test(target.value)) {
                                                        stateHandler('mobile',target?.value)
                                                    }
                                            }} value={state?.mobile} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" style={{marginBottom:'15px'}}>
                                            <label className="control-label">Enter Password</label>
                                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Password" onChange={({target}) => {
                                                stateHandler('password',target?.value)
                                            }} value={state?.password} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-3">
                                        <div className="form-group pt-3" style={{marginBottom:'15px'}}>
                                            <button onClick={()=>{
                                                addBankAccount()
                                            }} type="submit" className="btn btn-primary" style={{border:'0px',backgroundColor:'#0094a4'}}><i className="fa-solid fa-right-to-bracket"></i> Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="col-lg-12">
                    <div className='pageRight-mainWrapper'>
                        <div className="liveGame-section pageClass">
                            <div className="liveGame-titleBox pageClass">
                                <div className="siteTitle pageClass pb-0">
                                    <h2>Withdrawal History</h2>
                                </div>
                            </div>
                            <table className="table table-dark matchBet-table font-14">
                                <thead>
                                    <tr style={{textAlign:'center'}}>
                                        <th>S/N</th>
                                        <th>Bank Account</th>
                                        <th>UPI</th>
                                        <th>Mobile</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {withdrawalHistory?.map((item,index)=>(
                                        <tr style={{textAlign:'center'}}>
                                            <th style={{'width':'50px'}}>{index + 1}</th>
                                            <td>{(item?.account_number)?item?.account_number:'--'}</td>
                                            <td>{(item?.upi_id)?item?.upi_id:'--'}</td>
                                            <td>{item?.mobile}</td>
                                            <td>{item?.amount}</td>
                                            <td >{item?.status}</td>
                                            <td>{moment(item?.created_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                        </tr>
                                    ))}
                                    {(withdrawalHistory?.length == 0)&&
                                    <tr style={{textAlign:'center'}}>
                                        <td colSpan="7">No Record Found!</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>                            
        </div>
    );

}
export default Withdrawal;