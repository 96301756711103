import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {useDispatch,useSelector } from 'react-redux';
import { checkisMobile, decrypt } from 'config/helper';
import OddBat from './oddBat';
import FansyBat from './fansybat';
import CurrentBatHistory from './CurrentBatHistory';
import { POST } from 'config/api';
import global from 'config/global';
import { subscribe, unsubscribe } from 'config/event';
import RenderTv from './Component/RenderTV';
import { initSocket,getSocket, disconnectSocket  } from './../../../../../config/socket';
import { useLoader } from 'component/loader/loader';


const MatchBat = (props) => {
    const { showLoader } = useLoader();

    const dispatch = useDispatch()
    const isPageLoaded = useRef();

    const {matchData:reduxMatchData} = useSelector(state => state.buyerAuth);
    const {buyerdata,device_id} = useSelector(state => state.buyerAuth);

    const [matchData,setMatchData] = useState({});
    const [marketOdds,setMarketOdds] = useState({})
    const oldOdds = useRef({});


    let interval = '';
    useEffect(()=>{

        if(reduxMatchData) {
            let response = decrypt(reduxMatchData);
            let match_id = response?.match_id;
            
            
            setMatchData(response)
            getExposureItem(match_id)
            fetchMarketRunner(match_id)
            
            subscribe('refreshExposure',(data)=>{
                getExposureItem(match_id)
            });

            return ()=>{
                if(interval) {
                    const socketCLient = `client_${buyerdata?.id}_${match_id}_${device_id}`;
                    disconnectSocket(socketCLient);
                    clearInterval(interval);
                }
                unsubscribe('refreshExposure');
            }
        }        
    },[]);


    const fetchMarketRunner = (match_id) =>{
        try{
            if(isPageLoaded.current == undefined) {
                isPageLoaded.current = true;
                // showLoader(true);
    
                const socketCLient = `client_${buyerdata?.id}_${match_id}_${device_id}`;
                initSocket(socketCLient);
    
                let socket = getSocket();
                socket?.on('connected',()=>{
                    // initial call
                    socket?.emit('fetchOdds',{user_id:socketCLient,match_id:match_id,});

                    // interval call
                    interval =  setInterval(() => {
                        socket?.emit('fetchOdds',{user_id:socketCLient,match_id:match_id,});
                    }, global.ODDSREFRESHTIME);
                    
                    socket?.on('sendOdds', (data)=>{
                        if(data) {
                            let matchData = JSON.parse(data);
                            let newmatchData = checkValueIsChange(matchData);
                            setMarketOdds(newmatchData)
                        }
                        // showLoader(false)
                    });
                });
            }
        } catch(err){
            // showLoader(false)
        }
        
    }

    
    const getExposureItem = (match_id) => {
        let body = {
            match_id:match_id,
        }
        
        POST(`${global.W_BASEURL}customer/transaction/getExposureItem`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                if(data){
                    dispatch({type:'EXP_DATA',payload:data});
                }
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }
    const checkValueIsChange = (data) =>{

        let runnerObj = {...data};
        let newRunnerObj = {};

        for(let runnerKey in runnerObj) {
            let bhav = {};

            let runnerData = runnerObj[runnerKey];
            bhav = Object.assign(bhav,{[runnerKey]:{}});
            let msg = '';
            if(runnerData?.length) {
                let obj = {};
                let isNew = (oldOdds?.current?.[runnerKey] == undefined);
        
                for(let item of runnerData) {
                    let isUpdated = false;
                    let back_i = 0;
                    let lay_i = 0;
                    let sid = item.sid;
                    
                    /* set live bhav in context */
                    bhav[sid] = {back:{},lay:{}};
        
                    for(let item2 of item?.back_odds) {
                        /* set live bhav in context */
                        if(back_i == 0) { 
                            bhav[sid]['back'] = {...item2,status:item?.status};
                        }
                        
                        if(!isNew) {
                            // this for prevent blink odds box
                            item2.isUpdated = (oldOdds?.current?.[runnerKey][`${sid}_back_${back_i}`] != item2.price || oldOdds?.current?.[runnerKey][`${sid}_back_${back_i}_size`] != item2.size);
                            // this for prevent re render odd block row
                            if(item2?.isUpdated) {
                                isUpdated = item2?.isUpdated;    
                            }
                        }
                        
                        obj[`${sid}_back_${back_i}`] = item2.price;
                        obj[`${sid}_back_${back_i}_size`] = item2.size;
                        back_i++;
                    }
                    for(let item2 of item?.lay_odds) {
                        /* set live bhav in context */
                        if(lay_i == 0) { 
                            bhav[sid]['lay'] = {...item2,status:item?.status};;
                        }
                        
                        if(!isNew) {
                            // this for prevent blink odds box
                            item2.isUpdated = (oldOdds?.current?.[runnerKey][`${sid}_lay_${lay_i}`] != item2.price || oldOdds?.current?.[runnerKey][`${sid}_lay_${lay_i}_size`] != item2.size);
                            // this for prevent re render odd block row
                            if(item2?.isUpdated) {
                                isUpdated = item2?.isUpdated;    
                            }
                        }
                        obj[`${sid}_lay_${lay_i}`] = item2.price;
                        obj[`${sid}_lay_${lay_i}_size`] = item2.size;
                        lay_i++;
                    }
                    item.isUpdated = isUpdated;

                    msg = item?.msg
                }
        
                /* end  set live bhav in context */
                oldOdds.current[runnerKey] = obj;
            }

            newRunnerObj[runnerKey] = {liveOdds:bhav,msg:msg,data:runnerData};
        }

        return newRunnerObj;
    }


    return (
        <>
            <div className="col-lg-7">
                {(checkisMobile() == true) && <RenderTv match_id={matchData?.match_id} />}
                <OddBat matchname={`${matchData?.match_name} - ${matchData?.series_name}`} heading={'Match Odds'} bat_format="normal" series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds.odds} />
                <OddBat heading={'Bookmaker'} bat_format="bookmaker" series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds?.bookmaker} />
                <FansyBat series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds?.fansy}/>
            </div>
            <div className="col-lg-3">
                {(checkisMobile() == false) && <RenderTv mode={'desktop'} match_id={matchData?.match_id} />}
                <CurrentBatHistory type={'short'} match_id={matchData?.match_id} />
            </div>
        </>
    )
}



export default MatchBat
