import React, { memo, useState } from 'react'
import {POST} from 'config/api';
import global from 'config/global';
import {useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CasinoBatPlace = memo(({mode,batdata={},onClose}) =>{
    const {buyerdata} = useSelector(state => state.buyerAuth);
    
    const batPalace = ({stack}) =>{
        if(batdata?.mid != 0){
            let body = {
                mid:batdata?.mid,
                gtype:batdata?.gtype,
                bat_rate:batdata?.bat_rate,
                bat_stake:(batdata?.bat_multiply)?stack * batdata?.bat_multiply:stack,
                bat_type:batdata?.bat_type,
                sid:batdata?.sid,
                parent_id:buyerdata.parent_id
            }

            POST(`${global.CASINO_WEB}bat/batplace`,body).then((res)=>{
                if(res.data.status == 200) {
                    toast.info("Bat Palace Successfully",{autoClose: 2000});
                }
                if(res?.data?.status == 400){
                    toast.info(res?.data?.msg,{autoClose: 2000});
                }
            }).catch((err)=>{
                console.log(err,'/////');
            })
        }
    }
    


    const [stack,setstack] = useState(100);
    const stackArray = [
        100,250,500,750,1000,2000,3000,5000
    ]
    
    return (
        
        <div className={`liveGame-section pageClass ${(mode=='desktop')?'desktopTv-box':'mobileTv-box'}`} style={{marginTop:'10px'}}>
            <div className="liveGame-titleBox pageClass">
                <div className="siteTitle pageClass pb-0" style={{display:'flex',justifyContent:'space-between'}}>
                    <h2>Place Bet</h2>
                    <h2 onClick={onClose}>X</h2>
                </div>
            </div>
            <table className="table table-dark matchBet-table">
                <thead>
                    <tr>
                        <th>Bet for</th>
                        <th>odds</th>
                        <th>Stake</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{batdata?.gtype}</td>
                        <td><input type="text" value={batdata?.bat_rate} className="form-control rightBid-input" /></td>
                        <td><input type="text" value={stack} className="form-control rightBid-input" /></td>
                    </tr>
                </tbody>
            </table>
            <div className="placeBat-container">
                <ul className="quickBet-fild placeBat-grid">
                    {stackArray?.map((v,i)=>(
                        <li onClick={()=>{
                            setstack(v)
                        }}><a href="javascript:void(0);">{v}</a></li>
                    ))}
                </ul>
                <div className="row gx-2 justify-content-center">
                    <div onClick={()=>{
                        setstack(100)
                    }} className="col-auto">
                        <div className="link-btn"><a href="javascript:void(0);" data-bs-dismiss="modal">Reset</a></div>
                    </div>
                    <div onClick={()=>{
                        batPalace({stack})
                    }} className="col-auto">
                        <div className="link-btn1"><a href="javascript:void(0);">Place Bet</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
});
export default CasinoBatPlace;