import React from 'react'

function Lucky7({dataList={},suspended=true,onCLick=()=>{}}) {

    
    const handleOnCLick = (data) =>{
        let batitem = {
            bat_rate:data?.rate,
            bat_type:(data?.nation)?data?.nation:data.nat,
            sid:data?.sid,
        }
        onCLick(batitem);
    }


    return (
        <div className="newBet-wrapper lucky7Bet-box" style={{"backgroundColor":"#00676c"}}>
        <div className="topnewBet-box">
            <div className="row gx-3">
                <div className="col-lg-5 col-5">
                    <div onClick={()=>{
                        (!suspended) && handleOnCLick(dataList?.low_card)
                    }} className="innerNewBat-box pageClass ">
                        <h6>{dataList?.low_card?.rate}</h6>
                        <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                            {dataList?.low_card?.nation}
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-2 customCol-2" style={{alignContent:'center'}}>
                    <div className="text-center pageClass">
                        <img src="/assets/images/casino/7.png" />
                    </div>
                </div>
                <div className="col-lg-5 col-5">
                    <div onClick={()=>{
                        (!suspended) && handleOnCLick(dataList?.high_card)
                    }} className="innerNewBat-box pageClass">
                        <h6>{dataList?.high_card?.rate}</h6>
                        <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                            {dataList?.high_card?.nation}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="topnewBet-bottomBox">
            <div className="row">
                <div className="col-lg-6">
                    <div className="row gx-3">
                        <div className="col-sm-6 col-6">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                (!suspended) && handleOnCLick(dataList?.even)
                            }}>
                                <h6>{dataList?.even?.rate}</h6>
                                <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    {dataList?.even?.nation}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-6">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                (!suspended) && handleOnCLick(dataList?.odd)
                            }}>
                                <h6>{dataList?.odd?.rate}</h6>
                                <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    {dataList?.odd?.nation}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row gx-3">
                        <div className="col-sm-6 col-6">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                (!suspended) && handleOnCLick(dataList?.red)
                            }}>
                                <h6>{dataList?.red?.rate}</h6>
                                <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    <img src='/assets/images/casino/heart.png' style={{height:'25px',width:'25px'}} />
                                    <img src='/assets/images/casino/diamond.png' style={{height:'25px',width:'25px'}} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-6">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                (!suspended) && handleOnCLick(dataList?.black)
                            }}>
                                <h6>{dataList?.black?.rate}</h6>
                                <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    <img src='/assets/images/casino/spade.png' style={{height:'25px',width:'25px'}} />
                                    <img src='/assets/images/casino/club.png' style={{height:'25px',width:'25px'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="topnewBet-bottomBox">
            <div className="row">
                    <div className="twoColum-box">
                        <div className="screenCard-list-new">
                            {['a',2,3,4,5,6,7,8,9,10,'j','q','k']?.map((v,i)=>(
                                <img onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.[`card_${(v == 'a')?1:v}`])
                                }} src={`/assets/images/casino/${v}.png`} />
                            ))}
                        </div>
                    </div>
            </div>
        </div>
          </div>
    );

}
export default Lucky7;