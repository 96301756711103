import React from 'react'

function Card32B({dataList={},suspended=true,onCLick=()=>{}}) {
    
    const handleOnCLick = (data,bat_type) =>{
        let rate = (bat_type == 'back')?data?.b1:data.l1;
        let nation = (data?.nat)?data?.nat:data?.nation;
        let batitem = {
            bat_rate:rate,
            bat_type:(bat_type)?bat_type:nation,
            sid:data?.sid,
        }
        onCLick(batitem);
    }
    
    return (
        <div className="mainListing-container pageClass">
        <div className="container">
            <div className="casinoScreen-wrapper">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col">
                                <div className="row gx-2  align-items-center">
                                    <div className="col-7">
                                        <h6></h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6">
                                                <h5>Back</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>Lay</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 8</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_8,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_8?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_8,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_8?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 9</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_9,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_9?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_9,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_9?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 10</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_10,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_10?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_10,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_10?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 11</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_11,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_11?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_11,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_11?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col">
                                <div className="row gx-2  align-items-center">
                                    <div className="col-7">
                                        <h6></h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6">
                                                <h5>Odd</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>Even</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 8</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_8_odd,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_8_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_8_even,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_8_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 9</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_9_odd,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_9_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_9_even,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_9_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 10</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_10_odd,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_10_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_10_even,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_10_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Player 11</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_11_odd,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.player_11_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.player_11_even,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.player_11_even?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col">
                                <div className="row gx-2  align-items-center">
                                    <div className="col-7">
                                        <h6></h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6">
                                                <h5>Back</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>Lay</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Any Three Card Black</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.any_three_card_black,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.any_three_card_black?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.any_three_card_black,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.any_three_card_black?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Any Three Card Red</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.any_three_card_red,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.any_three_card_red?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.any_three_card_red,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.any_three_card_red?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>Two Black Two Red</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.two_black_two_red,'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.two_black_two_red?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.two_black_two_red,'lay')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice PinkPrice">
                                                    <a>{dataList?.two_black_two_red?.l1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col">
                                <div className="row gx-2  align-items-center">
                                    <div className="col-7">
                                        <h6></h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6">
                                                <h5>Back</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>Back</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>8 & 9 Total</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.[`8_&_9_total`],'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.[`8_&_9_total`]?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" >
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>0.00{(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7">
                                        <h6>10 & 11 Total</h6>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-1">
                                            <div className="col-6" onClick={()=>{
                                                handleOnCLick(dataList?.['10_&_11_total'],'back')
                                            }}>
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>{dataList?.['10_&_11_total']?.b1} {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                            <div className="col-6" >
                                                <div className="screen1-betBox pageClass bluePrice bluePrice">
                                                    <a>0.00 {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );

}
export default Card32B;