
import DragonTiger20 from './dragontiger/dt20';
import DragonTigerL20 from './dragontiger/dtl20';
import Card32 from './card32/card32';
import Card32B from './card32/card32b';
import CarinoWar from './casinowar/casinowar';
import Lucky7 from './lucky7/lucky7';
import AAA from './AAA/AAA';
import DDB from './ddb/ddb';
import DragonTiger1Day from './dragontiger/dt1day';
import AndarBahar from './andarbahar/andarbahar';
import Teen20 from './teenpati/teen20';
import Teep1Day from './teenpati/tp1day';
import Testtp from './teenpati/testp';
import Queen from './queen/queen';
import AndarBahar2 from './andarbahar/andarbahar2';
import Worliinstant from './worli/worliinstant';


const componentList = {
    'dt20':{component:DragonTiger20,title:'Dragon Tiger',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2'],slider:false}},
    'dt20b':{component:DragonTiger20,title:'Dragon Tiger-B',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2'],slider:false}},
    'dtl20':{component:DragonTigerL20,title:'20 20 D T L',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
    'lucky7':{component:Lucky7,title:'Lucky7',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1'],slider:false}},
    'lucky7eu':{component:Lucky7,title:'Lucky7-B',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1'],slider:false}},
    'card32a':{component:Card32,title:'Card32A',timerEnd:25,liveCardKey:{type:'card32',levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
    'card32b':{component:Card32B,title:'Card32-B',timerEnd:25,liveCardKey:{type:'card32b',levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
    'AAA':{component:AAA,title:'Amar Akbar Anthony',timerEnd:30,liveCardKey:{levelA:'',levelB:'',levelACards:['C1'],slider:false}},
    'ddb':{component:DDB,title:'Bollywood Casino',timerEnd:30,liveCardKey:{levelA:'',levelB:'',levelACards:['C1'],slider:false}},
    'warcasino':{component:CarinoWar,title:'Casino War',timerEnd:30,liveCardKey:{levelA:'DEALER',levelB:'',levelACards:['C7'],slider:false}},
    'dragontiger1Day':{component:DragonTiger1Day,title:'Dragon Tiger 1 Day',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2'],slider:false}},
    'Andarbahar':{component:AndarBahar,title:'Andar Bahar',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:true}},
    'Andarbahar2':{component:AndarBahar2,title:'Andar Bahar 2',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:true,dealer:true}},
    't20Odds':{component:Teen20,title:'Teen Patti 20-20',timerEnd:25,liveCardKey:{levelA:'Player A',levelB:'Player B',levelACards:['C1','C2','C3'],levelBCards:['C4','C5','C6'],slider:false}},
    'Tp1Day':{component:Teep1Day,title:'Teen Patti 1 Day',timerEnd:50,liveCardKey:{levelA:'Player A',levelB:'Player B',levelACards:['C1','C2','C3'],levelBCards:['C4','C5','C6']}},
    'testtp':{component:Testtp,title:'Test Teen Patti',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
    'Queen':{component:Queen,title:'Queen',timerEnd:25,liveCardKey:{type:'queen',levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
    'worliinstant':{component:Worliinstant,title:'Worli Instant',timerEnd:25,liveCardKey:{levelA:'',levelB:'',levelACards:['C1','C2','C3'],slider:false}},
}

export default componentList;