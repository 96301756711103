import { encryptTransform } from 'redux-persist-transform-encrypt'; // Import the encryption transform
import global from 'config/global';

// Define the secret key (ensure this key is secure and not hardcoded in production!)
const SECRET_KEY = global.ENCRYPTION_KEY; // Change this key to something secure

// Create the encryption transform
const encryptTransforms = encryptTransform({
  secretKey: SECRET_KEY, // Pass the secret key here
  onError: (error) => console.error('Error encrypting/decrypting state:', error)
});
export default encryptTransforms;
