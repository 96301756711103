let live = true;
let STATIC_IMAGE_PATH = 'https://localhost:3002/images/site_images/';
let IMAGE_PATH = 'https://localhost/crickbuzz/images/';
let BANK_IMAGE_PATH = 'https://img.vasudevexchange.com/images/bank/';
let LOGO_PATH = 'https://localhost/crickbuzz/images/logo/';
let A_BASEURL = 'http://localhost:3000/admin/v-1.0.0/';
let W_BASEURL = 'http://localhost:3000/web/v-1.0.0/';
let MatchAPI = 'http://localhost:3000/admin/v-1.0.0/';
let ENCRYPTION_KEY = 'bb1yT&0PZQ6oQ1XqT7Cs9/&3=C35CDA0AiB9oNx3^iHZNtgo3Ek8H11$vQr*OvxfibUj'
let delayTime = 2000;
let ODDSREFRESHTIME = 5000;
let SCORECARD = 'https://dpmatka.in/dcasino/score.php';
let LIVETV = 'https://hr08bets.in/sports-stream-live/index.html?eventid=';
let SITEURL = 'https://www.vasudevexchange.com';
let BACKENDURL = 'http://localhost:3000/';
let SOCKETURL = 'https://backend2.vasudevexchange.com/socket.io';
let CASINO_WEB = 'http://localhost:3000/casinoweb/v-1.0.0/';

let CASINO_GAME_TYPE = {"dt20": "dt20", "dt20b":"dt20b","card32b":"card32b","t20Odds":"t20Odds","worliinstant":"worliinstant","Tp1Day":"Tp1Day","testtp":"testtp","Queen":"Queen","dragontiger1Day":"dragontiger1Day","Andarbahar2":"Andarbahar2","Andarbahar":"Andarbahar","warcasino":"warcasino","dtl20":"dtl20","lucky7": "lucky7","lucky7eu":"lucky7eu","card32a":"card32","AAA":"aaa","ddb":"ddb"};

if(live)
{
    STATIC_IMAGE_PATH = 'https://img.vasudevexchange.com/images/site_images/';
    IMAGE_PATH = 'https://img.vasudevexchange.com/images/';
    BANK_IMAGE_PATH = 'https://img.vasudevexchange.com/images/bank/';
    LOGO_PATH = 'https://img.vasudevexchange.com/images/logo/';
    A_BASEURL = 'https://backend2.vasudevexchange.com/admin/v-1.0.0/';
    W_BASEURL = 'https://backend2.vasudevexchange.com/web/v-1.0.0/';
    MatchAPI = 'https://backend2.vasudevexchange.com/admin/v-1.0.0/';
    SOCKETURL = 'https://backend2.vasudevexchange.com/socket.io';
    CASINO_WEB = 'https://backend2.vasudevexchange.com/casinoweb/v-1.0.0/';
    BACKENDURL = 'https://backend2.vasudevexchange.com/';
}

const global = {
    IMAGE_PATH:IMAGE_PATH,
    LOGO_PATH:LOGO_PATH,
    W_BASEURL : W_BASEURL,
    ENCRYPTION_KEY:ENCRYPTION_KEY,
    A_BASEURL : A_BASEURL,
    STATIC_IMAGE_PATH:STATIC_IMAGE_PATH,
    MatchAPI:MatchAPI,
    delayTime:delayTime,
    SCORECARD:SCORECARD,
    LIVETV:LIVETV,
    SITEURL:SITEURL,
    BACKENDURL:BACKENDURL,
    SOCKETURL:SOCKETURL,
    CASINO_WEB:CASINO_WEB,
    CASINO_GAME_TYPE:CASINO_GAME_TYPE,
    LIVE:live,
    BANK_IMAGE_PATH:BANK_IMAGE_PATH,
    ODDSREFRESHTIME:ODDSREFRESHTIME
}


export default global;
