import React from 'react'

function DragonTiger1Day({dataList={},suspended=true,onCLick=()=>{}}) {


    
    const handleOnCLick = (data,bat_type) =>{
        if(!suspended) {
            let rate = (bat_type == 'lay')?data?.l1:data?.b1;
            
            let nation = (data?.nat)?data?.nat:data?.nation;

            let batitem = {
                bat_rate:rate,
                bat_type:(bat_type)?bat_type:nation,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    return (
        <div className="mainListing-container pageClass">
                <div className="container">
                    <div className="casinoScreen-wrapper">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="screen1-row">
                                    <div className="screen1-col">
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-7">
                                                <h6>Dargon</h6>
                                            </div>
                                            <div className="col-5">
                                                <div className="row gx-1">
                                                    <div className="col-6" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon,'back')
                                                    }}>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">{dataList?.dragon?.b1}</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon,'lay')
                                                    }}>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">{dataList?.dragon?.l1}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="screen1-row">
                                    <div className="screen1-col">
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-5">
                                                <h6>Pair</h6>
                                            </div>
                                            <div className="col-7" onClick={()=>{
                                                handleOnCLick(dataList?.pair)
                                            }}>
                                                <div className="screen1-betBox bluePrice pageClass">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">{dataList?.pair?.b1}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="screen1-row">
                                    <div className="screen1-col">
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-7">
                                                <h6>Tiger</h6>
                                            </div>
                                            <div className="col-5">
                                                <div className="row gx-1">
                                                    <div className="col-6" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger,'back')
                                                    }}>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">{dataList?.tiger?.b1}</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger,'lay')
                                                    }}>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">{dataList?.tiger?.l1}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="screen1-row">
                                    <div className="screen1-col pt-3">
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-12">
                                                <h6 className="pb-4">Dargon</h6>
                                            </div>
                                            <div className="col-12 pb-3">
                                                <div className="row gx-1">
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_even)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_even?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">Even</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_odd)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_odd?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">Odd</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_red)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_red?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} /> <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_black)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_black?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} /> <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 pb-3">
                                                <div className="row gx-1">
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_spade)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_spade?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_heart)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_heart?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_club)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_club?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.dragon_diamond)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_diamond?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="screen1-row">
                                    <div className="screen1-col pt-3">
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-12">
                                                <h6 className="pb-4">Tiger</h6>
                                            </div>
                                            <div className="col-12 pb-3">
                                                <div className="row gx-1">
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_even)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_even?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">Even</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_odd)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_odd?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">Odd</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_black)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_black?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">
                                                                <img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} /> <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_red)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_red?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);">
                                                                <img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} /> <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 pb-3">
                                                <div className="row gx-1">
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_spade)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_spade?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_heart)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_heart?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_club)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_club?.b1}</h6>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" onClick={()=>{
                                                        handleOnCLick(dataList?.tiger_diamond)
                                                    }}>
                                                        <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_diamond?.b1}</h6>
                                                        <div className="screen1-betBox PinkPrice pageClass">
                                                            {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                            <a href="javascript:void(0);"><img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
//         <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>
//             <div className="topnewBet-box">
//                 <div className="row gx-3">
//                     <div className="col-lg-3">
//                         <div onClick={()=>{
//                             handleOnCLick(dataList?.dragon,'back')
//                         }} className="innerNewBat-box pageClass ">
//                             <h6>{dataList?.dragon?.b1}</h6>
//                             <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                 {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                 Dargon
//                             </div>
//                         </div>
//                         <div onClick={()=>{
//                             handleOnCLick(dataList?.dragon,'lay')
//                         }} className="innerNewBat-box pageClass ">
//                             <h6>{dataList?.dragon?.l1}</h6>
//                             <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                 {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                 Dargon
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-3">
//                         <div onClick={()=>{
//                             handleOnCLick(dataList?.tiger,'back')
//                         }} className="innerNewBat-box pageClass">
//                             <h6>{dataList?.tiger?.b1}</h6>
//                             <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                 {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                 Tiger
//                             </div>
//                         </div>
//                         <div onClick={()=>{
//                             handleOnCLick(dataList?.tiger,'lay')
//                         }} className="innerNewBat-box pageClass">
//                             <h6>{dataList?.tiger?.l1}</h6>
//                             <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                 {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                 Tiger
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-3">
//                         <div onClick={()=>{
//                             handleOnCLick(dataList?.pair)
//                         }} className="innerNewBat-box pageClass">
//                             <h6>{dataList?.pair?.b1}</h6>
//                             <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                 {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                 Pair
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="topnewBet-bottomBox">
//                 <div className="row">
//                     <div className="col-lg-6">
//                         <h5 className="subBet-title">Dargon Name</h5>
//                         <div className="row gx-3">
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_even)
//                                 }}>
//                                     <h6>{dataList?.dragon_even?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         Even
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_odd)
//                                 }}>
//                                     <h6>{dataList?.dragon_odd?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         Odd
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_red)
//                                 }}>
//                                     <h6>{dataList?.dragon_red?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} />
//                                         <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_black)
//                                 }}>
//                                     <h6>{dataList?.dragon_black?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} />
//                                         <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-6">
//                         <h5  className="subBet-title">Tiger</h5>
//                         <div className="row gx-3">
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_even)
//                                 }}>
//                                     <h6>{dataList?.tiger_even?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         Even
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_odd)
//                                 }}>
//                                     <h6>{dataList?.tiger_odd?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         Odd
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_red)
//                                 }}>
//                                     <h6>{dataList?.tiger_red?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} />
//                                         <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_black)
//                                 }}>
//                                     <h6>{dataList?.tiger_black?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} />
//                                         <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             <div className="topnewBet-bottomBox">
//                 <div className="row">
//                     <div className="col-lg-6">
//                         <h5 className="subBet-title">Dargon Name</h5>
//                         <div className="row gx-3">
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_spade)
//                                 }}>
//                                     <h6>{dataList?.dragon_spade?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        
//                                         <img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_club)
//                                 }}>
//                                     <h6>{dataList?.dragon_club?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_heart)
//                                 }}>
//                                     <h6>{dataList?.dragon_heart?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.dragon_diamond)
//                                 }}>
//                                     <h6>{dataList?.dragon_diamond?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-6">
//                         <h5  className="subBet-title">Tiger</h5>
//                         <div className="row gx-3">
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_spade)
//                                 }}>
//                                     <h6>{dataList?.tiger_spade?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/spade.png' style={{height:'20px',width:'20px'}} />

//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_club)
//                                 }}>
//                                     <h6>{dataList?.tiger_club?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        
//                                         <img src='/assets/images/casino/club.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     handleOnCLick(dataList?.tiger_heart)
//                                 }}>
//                                     <h6>{dataList?.tiger_heart?.b1}</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/heart.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="innerNewBat-box pageClass" onClick={()=>{
//                                     hand{dataList?.tiger_diamond?.b1}nCLick(dataList?.tiger_diamond)
//                                 }}>
//                                     <h6>Even</h6>
//                                     <div className={`newBat-viewBox pageClass ${(suspended)&& 'btnOverlay1'}`}>
//                                         {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
//                                         <img src='/assets/images/casino/diamond.png' style={{height:'20px',width:'20px'}} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </div>
    );

}
export default DragonTiger1Day;