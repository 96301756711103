import React, { useState, useEffect } from 'react'
import Header from 'component/web/includes/header';
import Leftpanel from 'component/web/includes/leftpanel';
import { useSelector } from 'react-redux';
import NavBarPanel from '../includes/navBarPanel';
import Footer from '../includes/footer';

const  DashboardHoc = ({children}) =>{
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
    if(buyerdata?.id && buyerdata?.id != ''){
        return (
            <div>
                <Header/>
                <div className="main-content-wrapper">
                    <div className="mainListing-container pageClass">
                        <div className="container-fluid mb-2" style={{padding:'0px'}}>
    		                <NavBarPanel />
                            <div className="row gx-2">
                                <Leftpanel />
                                {children}
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <Header/>
                {children}
            </div>
        );
    }
	

}

export default DashboardHoc;
