import React, { memo, useEffect, useRef, useState } from 'react'
import {faTv,faCircle, faL} from '@fortawesome/free-solid-svg-icons';
import { counter, library } from '@fortawesome/fontawesome-svg-core';
import { POST } from 'config/api';

import global from 'config/global';
import moment from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import OddBatOuter from './../bat/oddBatOuter';
import { encrypt } from 'config/helper';
import { useDispatch, useSelector } from 'react-redux';
import { emit } from 'config/event';
import { getSocket } from 'config/socket';



export const MatchList = ({ishome,key,index,value={}}) =>{
	const dispatch = useDispatch()
    const isPageLoaded = useRef();

    const device_id = useSelector(state => state.buyerAuth?.device_id);
    const location = useLocation();
    library.add(faTv,faCircle);
    let history = useNavigate();
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
    
    const [matchList,setMatchList] = useState([]);

    let interval = '';
    
    useEffect(()=>{
        if(value?.event_id){
            fetchMatchList(value);
        }

        return ()=>{
            if(interval){
                clearInterval(interval)
                isPageLoaded.current = undefined;
            }
        }
    },[value?.event_id,location?.pathname]);



    const fetchMatchList = (value) => {
        let body ={
            sport_id:value.event_id,
            index:0,
            limit:5
        }
        
        if(location?.pathname?.indexOf('game') != -1){
            body.type = 'all';
            body.index = 0;
            body.limit = 15;
        }

        POST(`${global.W_BASEURL}front/home/fetchMatchList`,body).then((res)=>{
            if(res.data.status == 200) {
                let data  = res?.data?.data;
                if(data){
                    let counter = 0;
                    data?.map((v)=>{
                        (v?.inplay == 'true') && counter++;
                    })

                    let key = (value?.event_id == 4)?'cricket':(value?.event_id == 1)?'soccer':'tennis';
                    dispatch({type:'LIVECOUNTER',payload:{[key]:counter}})
                }
                
                setMatchList(data);
                fetchSocketMarketRunner(data,value.event_id)
            } else {
                setMatchList([]);
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const handleMatchClick = (content) =>{
        if(buyerdata?.token){

            const {series_id,match_id,name,openDate,series_name,sport_Id,timezone} = content;

            let body = {
                match_name:name,
                openDate:openDate,
                match_id:match_id,
                series_id:series_id,
                sport_id:sport_Id,
                timezone:timezone,
                series_name:series_name
            }
                
            let encryptedItem = encrypt(body);
            dispatch({
                type:'MATCHDATA',
                payload:encryptedItem
            });
            history(`/matchbat`);
        } else {
            emit('showLoginBatModal',true);
        }
    }


    const fetchSocketMarketRunner = (matchList,event_id) =>{

        if(isPageLoaded.current == undefined) {
            isPageLoaded.current = true;
            let socket = getSocket();

            if(socket?.connected){
                let matchArray = [];
                
                for(let item of matchList) {
                    matchArray?.push(item?.match_id)
                }
                

                // initial call
                const socketCLient = `client_${buyerdata?.id}_${device_id}`;
                socket?.emit('fetchOuterOdds',{user_id:socketCLient,matchList:matchList});

                // interval call
                interval =  setInterval(() => {
                    socket?.emit('fetchOuterOdds',{user_id:socketCLient,matchList:matchList});
                }, global.ODDSREFRESHTIME);

            }
            
        }
    }
    const makeMatchDate = (date,timezone) =>{
        try{
            if(date) {
                let d = moment(date).utc(timezone);
                return d.format('DD MMM h:mm A');
            } else {
                return '';
            }
        } catch(err){
            console.log(err)
        }
        
    }

    return (
        (matchList?.length > 0)&&
        <div key={key} className={`homeWrapper-box pageClass pb-1 mt-1`}>
            <div className="row gx-0 mb-1">
                <div className="col">
                    <div className="siteTitle pageClass pb-0 pl-5">
                        <h1>{value?.name}</h1>
                    </div>
                </div>
                {(location?.pathname?.indexOf('game') == -1 || ishome == true)&&
                    <div className="col-auto align-self-center">
                        <div className="urlLink-btn pageClass" onClick={()=>{
                            if(buyerdata?.token){
                                history(`/game/${value?.event_id}`);
                            } else {
                                emit('showLoginBatModal',true);
                            }
                        }} style={{marginRight:'5px'}}>
                            <a href="javascript:void(0);">View All <i className="fa-solid fa-arrow-right-long"></i></a>
                        </div>
                    </div>
                }
            </div>

            <div className="priceTable-boxMain pageClass">
                {matchList?.map((item,index)=>{
                    if(ishome != true || (ishome == true && index < 3)){
                        return (
                            <div key={`index${index}`} className="matchList-grid pageClass">
                                <div className="row">
                                    <div className="col-lg-7 align-self-center">
                                        <div onClick={()=>{
                                            handleMatchClick(item);
                                        }} className="matchTitle-box pageClass" style={{display:'flex',justifyContent:'space-between'}}>
                                            <a href="javascript:void(0);">
                                                {item?.name} <span className="timeGroup">{makeMatchDate(item?.openDate,item?.timezone)} {(item?.inplay=='true')&& <i className="fa-solid fa-circle"></i>} </span>  
                                            </a>
                                            <div>
                                                {(item?.bm=='true')&& <img src='/assets/images/bm.svg' style={{height:'30px',width:'30px',marginRight:'5px'}} />} 
                                                {(item?.fancy=='true')&& <img src='/assets/images/fancy.svg' style={{height:'25px',width:'25px'}} />}  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="priceGroup-box pageClass">
                                            <OddBatOuter key={`oddbatouter_${index}`} ishome={ishome} match_id={item?.match_id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    );
};