import React from 'react'

function CarinoWar({dataList={},t1={},suspended=true,onCLick=()=>{}}) {
    
    const handleOnCLick = (data) =>{
        if(!suspended){
            let batitem = {
                bat_rate:data?.b1,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    const trList = [
        {name:'Winner',img:[],game1:dataList?.winner_1,game2:dataList?.winner_2,game3:dataList?.winner_3,game4:dataList?.winner_4,game5:dataList?.winner_5,game6:dataList?.winner_6},
        {name:'Black',img:['/assets/images/casino/spade.png','/assets/images/casino/club.png'],game1:dataList?.black_1,game2:dataList?.black_2,game3:dataList?.black_3,game4:dataList?.black_4,game5:dataList?.black_5,game6:dataList?.black_6},
        {name:'Red',img:['/assets/images/casino/heart.png','/assets/images/casino/diamond.png'],game1:dataList?.red_1,game2:dataList?.red_2,game3:dataList?.red_3,game4:dataList?.red_4,game5:dataList?.red_5,game6:dataList?.red_6},
        {name:'Odd',img:[],game1:dataList?.odd_1,game2:dataList?.odd_2,game3:dataList?.odd_3,game4:dataList?.odd_4,game5:dataList?.odd_5,game6:dataList?.odd_6},
        {name:'Even',img:[],game1:dataList?.even_1,game2:dataList?.even_2,game3:dataList?.even_3,game4:dataList?.even_4,game5:dataList?.even_5,game6:dataList?.even_6},
        {name:'',img:['/assets/images/casino/spade.png'],game1:dataList?.spade_1,game2:dataList?.spade_2,game3:dataList?.spade_3,game4:dataList?.spade_4,game5:dataList?.spade_5,game6:dataList?.spade_6},
        {name:'',img:['/assets/images/casino/heart.png'],game1:dataList?.heart_1,game2:dataList?.heart_2,game3:dataList?.heart_3,game4:dataList?.heart_4,game5:dataList?.heart_5,game6:dataList?.heart_6},
        {name:'',img:['/assets/images/casino/club.png'],game1:dataList?.club_1,game2:dataList?.club_2,game3:dataList?.club_3,game4:dataList?.club_4,game5:dataList?.club_5,game6:dataList?.club_6},
        {name:'',img:['/assets/images/casino/diamond.png'],game1:dataList?.diamond_1,game2:dataList?.diamond_2,game3:dataList?.diamond_3,game4:dataList?.diamond_4,game5:dataList?.diamond_5,game6:dataList?.diamond_6},
    ]

    const renderImg = (card) =>{
        card = (card && card != '1')?card:'blank';
        return <img src={`/assets/images/casino/${card}.png`} className={(card != 'blank')?'flip-image loaded':'blank-flip-image loaded'} style={{height:'56px',width:'40px'}} loading="lazy"/>
    }
    return (
        
        <div className="mainListing-container pageClass">
                <div className="container">
                    <div className="casinoScreen-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="screen1-row screen2-game">
                                    <div className="screen1-col">
                                        <div className="row gx-2  align-items-center">
                                            <div className="col-lg-3">
                                                <h6></h6>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="row gx-1">
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                            {renderImg(t1?.C1)}
                                                        </div>
                                                        <h6>1</h6>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                        {renderImg(t1?.C2)}
                                                        </div>
                                                        <h6>2</h6>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                        {renderImg(t1?.C3)}
                                                        </div>
                                                        <h6>3</h6>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                        {renderImg(t1?.C4)}
                                                        </div>
                                                        <h6>4</h6>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                        {renderImg(t1?.C5)}
                                                        </div>
                                                        <h6>5</h6>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <div className="screenCard-pic pageClass">
                                                        {renderImg(t1?.C6)}
                                                        </div>
                                                        <h6>6</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {trList?.map((v,i)=>{
                                        return (
                                            <div className="screen1-col">
                                                <div className="row gx-2 align-items-center">
                                                    <div className="col-lg-3" style={{display:'flex'}}>
                                                        <h6>{v?.name}</h6>
                                                        {v?.img?.map((img)=><img src={img} style={{marginLeft:'5px',height:'30px',width:'25px'}} />)}
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="row gx-1">
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game1)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game1?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game2)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game2?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game3)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game3?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game4)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game4?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game5)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game5?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-2" onClick={()=>{handleOnCLick(v?.game6)}}>
                                                                <div className="screen1-betBox pageClass bluePrice">
                                                                    <a className='bluePrice'>{v?.game6?.b1} 
                                                                        {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

}
export default CarinoWar;