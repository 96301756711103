import { emit } from 'config/event';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CasinoList = ({isHome=false}) =>{
	let navigate = useNavigate();
    const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
    
const casinoGames = [
    {key:'dt20',img:'/assets/images/casino/games/dt20.jpg'},
    {key:'warcasino',img:'/assets/images/casino/games/war.jpg'},
    {key:'Andarbahar',img:'/assets/images/casino/games/ab20.jpg'},
    {key:'lucky7',img:'/assets/images/casino/games/lucky7.jpg'},
    {key:'dragontiger1Day',img:'/assets/images/casino/games/dt6.jpg'},
    {key:'card32a',img:'/assets/images/casino/games/card32.jpg'},
    {key:'dtl20',img:'/assets/images/casino/games/dtl20.jpg'},
    {key:'AAA',img:'/assets/images/casino/games/aaa.jpg'},
    {key:'ddb',img:'/assets/images/casino/games/ddb.jpg'},
    {key:'t20Odds',img:'/assets/images/casino/games/teen20.jpg'},
    {key:'Tp1Day',img:'/assets/images/casino/games/teen.jpg'},
    {key:'Andarbahar2',img:'/assets/images/casino/games/abj.jpg'},
    {key:'dt20b',img:'/assets/images/casino/games/dt202.jpg'},
    {key:'testtp',img:'/assets/images/casino/games/teen9.jpg'},
    {key:'card32b',img:'/assets/images/casino/games/card32eu.jpg'},
    {key:'lucky7eu',img:'/assets/images/casino/games/lucky7eu.jpg'},
    {key:'Queen',img:'/assets/images/casino/games/queen.jpg'},
    {key:'worliinstant',img:'/assets/images/casino/games/worli2.jpg'},
];

return (
    <div className={(isHome)?'':"col-lg-10"}>
        <div className='pageRight-mainWrapper'>
            <div className="homeWrapper-box pageClass">
                <div className="pageClass mb-4">
                        <div className="siteTitle pageClass pb-0">
                            <h1>Live Casino</h1>
                        </div>
                </div>

                <ul className="liveGame-listing pageClass">
                    {casinoGames?.map((v,i)=>(
                        <li onClick={()=>{
                            if(buyerdata?.id){
                                navigate(`/casino/${v?.key}`);
                            } else {
                                emit('showLoginBatModal',true);
                            }
                        }}>
                            <div className="liveGame-imgWrapper pageClass">
                                <a href="javascript:void(0);">
                                    <div className="liveGame-imgHolder">
                                        <figure><img src={v?.img} alt="Vasudev" /></figure>
                                    </div>
                                </a>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
  );
}
export default CasinoList;
