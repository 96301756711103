import React from 'react'

function DragonTigerL20({dataList={},suspended=true,onCLick=()=>{}}) {


    
    const handleOnCLick = (data) =>{
        if(!suspended){
            let batitem = {
                bat_rate:data?.b1,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    const trList1 = [
        {name:'Winner',img:[],dragon:dataList?.winner_d,tiger:dataList?.winner_t,lion:dataList?.winner_l},
        {name:'Black',img:['/assets/images/casino/spade.png','/assets/images/casino/club.png'],dragon:dataList?.black_d,tiger:dataList?.black_t,lion:dataList?.black_l},
        {name:'Red',img:['/assets/images/casino/heart.png','/assets/images/casino/diamond.png'],dragon:dataList?.red_d,tiger:dataList?.red_t,lion:dataList?.red_l},
        {name:'Odd',img:[],dragon:dataList?.odd_d,tiger:dataList?.odd_t,lion:dataList?.odd_l},
        {name:'Even',img:[],dragon:dataList?.even_d,tiger:dataList?.even_t,lion:dataList?.even_l},
        {name:'',img:['/assets/images/casino/a.png'],dragon:dataList?.dragon_a,tiger:dataList?.tiger_a,lion:dataList?.lion_a},
        {name:'',img:['/assets/images/casino/2.png'],dragon:dataList?.dragon_2,tiger:dataList?.tiger_2,lion:dataList?.lion_2},
        {name:'',img:['/assets/images/casino/3.png'],dragon:dataList?.dragon_3,tiger:dataList?.tiger_3,lion:dataList?.lion_3},
        {name:'',img:['/assets/images/casino/4.png'],dragon:dataList?.dragon_4,tiger:dataList?.tiger_4,lion:dataList?.lion_4},
    ]
    const trList2 = [
        {name:'',img:['/assets/images/casino/5.png'],dragon:dataList?.dragon_5,tiger:dataList?.tiger_5,lion:dataList?.lion_5},
        {name:'',img:['/assets/images/casino/6.png'],dragon:dataList?.dragon_6,tiger:dataList?.tiger_6,lion:dataList?.lion_6},
        {name:'',img:['/assets/images/casino/7.png'],dragon:dataList?.dragon_7,tiger:dataList?.tiger_7,lion:dataList?.lion_7},
        {name:'',img:['/assets/images/casino/8.png'],dragon:dataList?.dragon_8,tiger:dataList?.tiger_8,lion:dataList?.lion_8},
        {name:'',img:['/assets/images/casino/9.png'],dragon:dataList?.dragon_9,tiger:dataList?.tiger_9,lion:dataList?.lion_9},
        {name:'',img:['/assets/images/casino/10.png'],dragon:dataList?.dragon_10,tiger:dataList?.tiger_10,lion:dataList?.lion_10},
        {name:'',img:['/assets/images/casino/j.png'],dragon:dataList?.dragon_j,tiger:dataList?.tiger_j,lion:dataList?.lion_j},
        {name:'',img:['/assets/images/casino/q.png'],dragon:dataList?.dragon_q,tiger:dataList?.tiger_q,lion:dataList?.lion_q},
        {name:'',img:['/assets/images/casino/k.png'],dragon:dataList?.dragon_k,tiger:dataList?.tiger_k,lion:dataList?.lion_k},
    ]

    return (
        
        <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>
            <div className='col-md-12 row'>
                <div className="col-md-6">
                    <table className="table table-bordered ">
                        <thead>
                            <tr >
                                <th style={{width:'40%'}} scope="col"></th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Dragon</th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Tiger</th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Lion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trList1?.map((v,i)=>(
                                <tr style={{height:'50px'}}>
                                    <td>
                                        {v?.name}
                                        {v?.img?.map((img)=><img src={img} style={{marginLeft:'5px',height:'30px',width:'25px'}} />)}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.dragon)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.dragon?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.tiger)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.tiger?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.lion)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.lion?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6">
                    <table className="table table-bordered ">
                        <thead>
                            <tr >
                                <th style={{width:'40%'}} scope="col"></th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Dragon</th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Tiger</th>
                                <th className='text-center' style={{backgroundColor:'#72bbef'}} scope="col">Lion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trList2?.map((v,i)=>(
                                <tr style={{height:'50px'}}>
                                    <td>
                                        {v?.name}
                                        {v?.img?.map((img)=><img src={img} style={{marginLeft:'5px',height:'30px',width:'25px'}} />)}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.dragon)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.dragon?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.tiger)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.tiger?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                    <td onClick={()=>{handleOnCLick(v?.lion)}} className={`pageClass text-center ${(suspended)&& 'btnOverlay1'}`} style={{backgroundColor:'#72bbef',display:"table-cell"}}>
                                        {v?.lion?.b1}
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

}
export default DragonTigerL20;