import Axios from "axios"
import {store} from './../redux/store';
import { decrypt, encrypt } from "./helper";
import global from "./global";


const getEncryptedUrl = (url) =>{
    if(url){
        let pathname = new URL(url)?.pathname;
        let encrypted_url = encrypt(pathname);
        return `${global.BACKENDURL}enc_query?_url=${encodeURIComponent(encrypted_url)}`;
    } else {
        return '';
    }
}

export const POST = (url,body) =>{
    try {
        const {buyerAuth} = store.getState();
        const {buyerdata} = buyerAuth;
        let headers = {
            headers: {
                'Authorization': `${buyerdata?.token}` 
            }
        }
        body = (body)?body:{};
        body = {_content:encrypt(body)};
        
        return new Promise((resolve,reject)=>(
            Axios.post(getEncryptedUrl(url),body,headers).then((res)=>{

                let decryptData = {};
                if(res?.data?.data?._content){
                    decryptData = decrypt(res?.data?.data?._content);
                } 
                let response = {...res,data:decryptData}
                resolve(response)
            }).catch((err)=>{
                if(err?.response?.status == 401) {
                    store.dispatch({type:'BLOGOUT'});
                    if(window.location.pathname != '/' && window.location.pathname != '/index' && window.location.pathname != '/home') {
                        window.location.href = '/';
                    }
                } else {
                    reject(err)
                }
            })    
        ))
    } catch(err){
        console.log('err')
    }
}

export const GET = (url,token) =>{
    try {
    
        const {buyerAuth} = store.getState();
        const {buyerdata} = buyerAuth;
        let headers = {
            headers: {
                'Authorization': `${buyerdata?.token}` 
            }
        }
        

        return new Promise((resolve,reject)=>(
            Axios.get(getEncryptedUrl(url),headers).then((res)=>{
                let decryptData = {};
                if(res?.data?.data?._content){
                    decryptData = decrypt(res?.data?.data?._content);
                } 
                let response = {...res,data:decryptData}
                resolve(response)
            }).catch((err)=>{
                
                if(err?.response?.status == 401) {
                    store.dispatch({type:'BLOGOUT'});
                    if(window.location.pathname != '/' && window.location.pathname != '/index' && window.location.pathname != '/home') {
                        window.location.href = '/';
                    }
                } else {
                    reject(err)
                }
            })    
        ))
    } catch(err){
        console.log('err')
    }
}
