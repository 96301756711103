import {BLOGIN,BLOGOUT} from './actionTypes';
import Axios from 'axios';
import global from '../../config/global';
import { v4 as uuid } from 'uuid'
import { POST } from 'config/api';

export function BuyerLogin(body){
    return function(dispatch) {
        let deviceId = uuid()
        body.device_id = deviceId;

        return POST(global.W_BASEURL+'customer/auth/login',body).then((res)=>{

            if(res.data.status==200)
            {
                dispatch({
                    type:'SITECONFIG',
                    payload:res?.data?.data?.siteConfig
                });


                dispatch({
                    type:'DEVICE_ID',
                    payload:deviceId
                });
                
                dispatch({
                    type:BLOGIN,
                    payload:res?.data?.data?.user_data
                });
                
                makeStakeArray(res?.data?.data?.stakeData,dispatch)

                return {
                    status:res.data?.status,
                    first_time_pass_change:res?.data?.data?.user_data.first_time_pass_change
                };
            }
            else {
                return {};
            }
            
        }).catch((err)=>{
            console.log(err);
        });
    };  
};
 


const makeStakeArray = (data,dispatch) =>{
    let defaultStakeList = [
        {key:'100',val:100},
        {key:'200',val:200},
        {key:'300',val:300},
        {key:'400',val:400},
        {key:'500',val:500},
        {key:'600',val:600},
        {key:'700',val:700},
        {key:'800',val:800},
    ];

    let stackList = [];

    if(data){
      
      if(data){
          let lebalCount = 1;
          for(let item in data){
            if(item?.indexOf(`stack_lable_${lebalCount}`) != -1){
                stackList.push({key : data[`stack_lable_${lebalCount}`], val:data[`stack_lable_val_${lebalCount}`]});
                lebalCount++;
            }
         }
      }
    }

    if(stackList?.length == 0){
        stackList =  defaultStakeList ;
    }
    
   dispatch({
        type:'STAKELIST',
        payload:stackList
    });
 }

 
export function Logout(){
    return function(dispatch) {
        return new Promise(function(resolve, reject) {
            dispatch({
                type:BLOGOUT,
                payload:{}
            });
            resolve(true);
        });
        
    };  
};
