import React, { memo, useEffect, useState } from 'react'

const RenderCasinoTv = memo(({tvUrl,title=''}) => {
        return (
        <div style={{marginBottom:'4px'}} >
            <div className="tableTitle-box" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                <h4>{title}</h4>
            </div>
            <div className='liveTv-show' style={{height:'400px',backgroundColor:'#000000'}}>
                {(tvUrl)&&<iframe src={`${tvUrl}`} style={{height:'400px'}} ></iframe>}
            </div>
        </div>
    )
});

export default RenderCasinoTv;
