import React from 'react'

function Teen20({dataList={},suspended=true,onCLick=()=>{}}) {
    
    const handleOnCLick = (data) =>{
        if(!suspended){
            let batitem = {
                bat_rate:data?.rate,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    const trList = [
        {name:'Player A',data:dataList?.player_a},
        {name:'Player B',data:dataList?.player_b},
        {name:'Pair Plus A',data:dataList?.pair_plus_a},
        {name:'Pair Plus B',data:dataList?.pair_plus_b},
    ]

    return (
        <div className="mainListing-container pageClass">
                <div className="container">
                    <div className="casinoScreen-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="screen1-row screen2-game">
                                    <div className="screen1-col">
                                        <div className="row gx-2  align-items-center">
                                            <div className="col-lg-8">
                                                <h6></h6>
                                            </div>
                                            <div className="col-lg-4 text-center">
                                                <h6>Back</h6>
                                            </div>
                                        </div>
                                    </div>

                                    {trList?.map((v,i)=>{
                                        return (
                                            <div className="screen1-col">
                                                <div className="row gx-2 align-items-center">
                                                    <div className="col-lg-8 mb-2" style={{display:'flex'}}>
                                                        <h6>{v?.name}</h6>
                                                    </div>
                                                    <div className="col-lg-4" onClick={()=>{handleOnCLick(v?.data)}}>
                                                        <div className="screen1-betBox pageClass bluePrice">
                                                            <a className='bluePrice'>{v?.data?.rate} 
                                                                {(suspended)&&<div className='overlayText'><i className="fa-solid fa-lock"></i></div>}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

}
export default Teen20;