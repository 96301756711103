import React from 'react'

function DragonTiger20({dataList={},suspended=true,onCLick=()=>{}}) {


    
    const handleOnCLick = (data) =>{
        if(!suspended){
            let batitem = {
                bat_rate:data?.rate,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    return (
        
    <div className="mainListing-container pageClass">
        <div className="container">
            <div className="casinoScreen-wrapper">
                <div className="row">
                    <div className="col-lg-4 col-8">
                        <div className="screen1-row screen4-box screenLooks-btn">
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7" onClick={()=>{
                                        handleOnCLick(dataList?.dragon)
                                    }}>
                                        <h6>Dargon <br/> <span className="mt-1" style={{"display": "inline-block"}}>{dataList?.dragon?.rate}</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                                        
                    <div className="col-lg-2 col-4">
                        <div className="screen1-row screen4-box screenLooks-btn">
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-5" onClick={()=>{
                                        handleOnCLick(dataList?.tie)
                                    }}>
                                        <h6>Tie <br/> <span className="mt-1" style={{"display": "inline-block"}}>{dataList?.tie?.rate}</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-8">
                        <div className="screen1-row screen4-box screenLooks-btn">
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-7" onClick={()=>{
                                        handleOnCLick(dataList?.tiger)
                                    }}>
                                        <h6>Tiger <br/> <span className="mt-1" style={{"display": "inline-block"}}>{dataList?.tiger?.rate}</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-4">
                        <div className="screen1-row screen4-box screenLooks-btn">
                            <div className="screen1-col">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-5" onClick={()=>{
                                        handleOnCLick(dataList?.pair)
                                    }}>
                                        <h6>Pair <br/> <span className="mt-1" style={{"display": "inline-block"}}>{dataList?.pair?.rate}</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col pt-3">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-12">
                                        <h6 className="pb-4">Dargon</h6>
                                    </div>
                                    <div className="col-12 pb-3">
                                        <div className="row gx-1">
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.dragon_even)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_even?.rate}</h6>
                                                <div className="screen1-betBox pageClass bluePrice">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">Even</a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.dragon_odd)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_odd?.rate}</h6>
                                                <div className="screen1-betBox PinkPrice pageClass">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">Odd</a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.dragon_red)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_red?.rate}</h6>
                                                <div className="screen1-betBox pageClass bluePrice">
                                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">
                                                        <img src='/assets/images/casino/heart.png' style={{height:'25px',width:'25px'}} />
                                                        <img src='/assets/images/casino/diamond.png' style={{height:'25px',width:'25px'}} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.dragon_black)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.dragon_black?.rate}</h6>
                                                <div className="screen1-betBox PinkPrice pageClass">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">
                                                        <img src='/assets/images/casino/spade.png' style={{height:'25px',width:'25px'}} />
                                                        <img src='/assets/images/casino/club.png' style={{height:'25px',width:'25px'}} />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-3">
                                        <h6 className="pb-2 pt-1 text-center"></h6>
                                        <div>
                                            <div className="screenCard-list-new">
                                                {['a',2,3,4,5,6,7,8,9,10,'j','q','k']?.map((v,i)=>(
                                                    <img onClick={()=>{
                                                        handleOnCLick(dataList?.[`dragon_card_${(v=='a')?1:v}`])
                                                    }} src={`/assets/images/casino/${v}.png`} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="screen1-row">
                            <div className="screen1-col pt-3">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-12">
                                        <h6 className="pb-4">Tiger</h6>
                                    </div>
                                    <div className="col-12 pb-3">
                                        <div className="row gx-1">
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.tiger_even)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_even?.rate} </h6>
                                                <div className="screen1-betBox pageClass bluePrice">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">Even</a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.tiger_odd)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_odd?.rate}</h6>
                                                <div className="screen1-betBox PinkPrice pageClass">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">Odd</a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.tiger_red)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_red?.rate}</h6>
                                                <div className="screen1-betBox pageClass bluePrice">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">
                                                        <img src='/assets/images/casino/heart.png' style={{height:'25px',width:'25px'}} />
                                                        <img src='/assets/images/casino/diamond.png' style={{height:'25px',width:'25px'}} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3" onClick={()=>{
                                                handleOnCLick(dataList?.tiger_black)
                                            }}>
                                                <h6 className="pb-2 pt-1 text-center">{dataList?.tiger_black?.rate}</h6>
                                                <div className="screen1-betBox PinkPrice pageClass">
                                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                                    <a href="javascript:void(0);">
                                                    <img src='/assets/images/casino/spade.png' style={{height:'25px',width:'25px'}} />
                                                    <img src='/assets/images/casino/club.png' style={{height:'25px',width:'25px'}} />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-3">
                                        <h6 className="pb-2 pt-1 text-center"></h6>
                                        <div className="screenCard-list-new">
                                            {['a',2,3,4,5,6,7,8,9,10,'j','q','k']?.map((v,i)=>(
                                                <img onClick={()=>{
                                                    handleOnCLick(dataList?.[`tiger_card_${(v == 'a')?1:v}`])
                                                }} src={`/assets/images/casino/${v}.png`} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );

}
export default DragonTiger20;