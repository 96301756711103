const initialState = {
    buyerdata:{},
    exposureData:{},
    fansyData:{},
    sports_id:''
};

export const MainReducer = (state = initialState, action)=> {
    switch (action.type){
        case 'SPORTS_ID':
            return {
                ...state,sports_id:action.payload
            }
        case 'EXP_DATA':
            return {
                ...state,exposureData:action.payload
            }
        case 'EXP_FANSY':
            return {
                ...state,fansyData:action.payload
            }
        default:
            return state;
    }
}