// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


let firebaseNotification = {
  getToken:()=>{},
  initializeSW:()=>{}
}

if(isBrowserSupported()){
  
  const firebaseConfig = {
    apiKey: "AIzaSyAClK5v09XUoynmmPSvq9qMbX2q1ll1x1k",
    authDomain: "vasudevexchange-35173.firebaseapp.com",
    projectId: "vasudevexchange-35173",
    storageBucket: "vasudevexchange-35173.firebasestorage.app",
    messagingSenderId: "30188187263",
    appId: "1:30188187263:web:3507e31c83a296c13a194a",
    measurementId: "G-JDH43ST07Y"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  const getTokens = () =>{  
    return new Promise(async resolve =>{
      try {
          let permissions = await Notification.requestPermission()
          if(permissions == 'granted'){
            getToken(messaging, { vapidKey: 'BK4PVaFqjkvMbmP55HwKNWIZrG0jispPfSYwoZlMCoEx4Smkx3rtizrhYMyAP6mHKJ30wpm_a1Vdi9SKzec-Zno' }).then((token)=>{
              return resolve(token);
            }).catch((err)=>{
              return resolve('');
            });
          } else {
            return resolve('');
          }
      } catch (error) {
        console.error('Error getting FCM token:', error);
        return resolve('');
      }
    })  
  }

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
  });

  const initializeSW = () =>{
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' })
      .then(registration => {
        getTokens()
        console.log('Service Worker registered with custom scope:', registration.scope);
      })
      .catch(err => {
        console.error('Service Worker registration failed:', err);
      });
    }
  }

  firebaseNotification = {
    getToken:getTokens,
    initializeSW:initializeSW
  }
}


function isBrowserSupported() {
  return ('serviceWorker' in navigator && 'PushManager' in window);
}

export default firebaseNotification;
