import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { abbreviateNumber } from 'config/helper';
import { useSelector } from 'react-redux';


const memoCompare = (prevProps, nextProps) =>{
    if((nextProps?.isUpdated == true) || (prevProps?.forceRefresh != nextProps?.forceRefresh)) {
        return false;
    } else {
        return true;
    }
}

const RenderMatchItem = memo(({value,bat_format,onBetClick}) => {
    /** use when some time data are not available in back like 0 bhav item array not come in back array */
    const {exposureData} = useSelector(state => state.buyerGlobal);
    const {buyerdata} = useSelector((state) => state.buyerAuth);
    
    const onPress = (batItem,index,type) =>{
        let enableIndex = (type == 'back') ? 2:0
        if(!value.is_suspend && !value.is_ball_running && index === enableIndex)
        {
            let content = {
                runnerName:value.team_name,
                runner_id:value.sid,
                batRate:batItem.price,
                battype:type,
                bat_format:bat_format, 
            }
            onBetClick(content)
        }
    }
    
    let exposureObj = (exposureData?.[bat_format])?exposureData[bat_format]:{};
    const checkExposureValue = (value) =>{
        let sid = value?.sid
        if(sid && exposureObj && exposureObj[sid]) {
            let exposure_value = exposureObj?.[sid];
            if(Math.sign(exposure_value) == -1) {
                return <span style={{marginLeft:'10px',color:'red'}}>{exposure_value}</span>
            } else {
                return <span style={{marginLeft:'10px',color:'green'}}>{exposure_value}</span>
            }

        } else {
            return '';
        }
    }
    const checkBatIsSuspended = () =>(value?.status == 'SUSPENDED' || value?.status == 'Ball Running' || (value?.user_lock && value?.user_lock?.indexOf(buyerdata?.id) != -1) || (value?.staff_lock && value?.staff_lock?.indexOf(buyerdata?.parent_id) != -1));
    
    const suspendMsg = (msg) =>{
        if((value?.user_lock && value?.user_lock?.indexOf(buyerdata?.id) != -1) || (value?.staff_lock && value?.staff_lock?.indexOf(buyerdata?.parent_id) != -1)){
            return 'SUSPENDED'
        } else {
            return msg
        }
    };
    
/** use when some time data are not available in back like 0 bhav item array not come in back array */
    const getLayBackFullArray = (itemArray) =>{
        let remainingItem = 3 - itemArray?.length;
        let newArray = [...itemArray];

        if(remainingItem) {
            Array(remainingItem).fill().map((v, i) =>{
                newArray.push({
                    isUpdated: false,
                    price: 0,
                    size: 0,
                })
            })
        }
        return newArray;
    }

    let back_odds = getLayBackFullArray(value?.back_odds);
    let lay_odds = getLayBackFullArray(value?.lay_odds);
    
    const getPrice = (item) =>(item?.price1)?item?.price1:item?.price;

    return (

        <div className="matchList-grid pageClass">
            <div className="row">
                <div className="col-6 align-self-center">
                    <div className="matchTitle-box pageClass">
                        <a href="javascript:void(0);">
                            {value.team_name} <span style={{fontSize:'13px'}}>{checkExposureValue(value)}</span>
                        </a>
                    </div>
                </div>
                <div className="col-6">
                    <div className="priceGroup-box pageClass newList-gridBox">
                        <div className="row gx-2">
                        {(checkBatIsSuspended())&&<div className="overlayText" style={{color:'red'}}>{value?.status}</div>}
                            <div className="col">
                                <div className="priceColum-row">
                                    {back_odds?.reverse()?.map((item,index)=>(
                                        <div key={`back_${index}`} onClick={()=>{
                                            if(!checkBatIsSuspended() && getPrice(item) > 0) {
                                                onPress(item,index,'back')
                                            }
                                        }} className={`priceColum-grid ${(index != 2) && "mobNone-box"} `}>
                                            <a style={{height:'45px'}} data-bs-toggle="modal" data-bs-target="#betModal" className={`bluePrice ${(item?.isUpdated == true) ? 'colorBlinkBack' : ''}`}>{getPrice(item)} <br /> {abbreviateNumber(item?.size)}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col">
                                <div className="priceColum-row">
                                    {lay_odds?.map((item,index)=>(
                                        <div key={`lay_${index}`} onClick={()=>{
                                            if(!checkBatIsSuspended() && getPrice(item) > 0) {
                                                onPress(item,index,'lay')
                                            }
                                        }} className={`priceColum-grid ${(index != 0) && "mobNone-box"}`}>
                                            <a style={{height:'45px'}} data-bs-toggle="modal" data-bs-target="#betModal" className={`PinkPrice ${(item?.isUpdated == true ) ? 'colorBlinkLay' : ''}`}>{getPrice(item)} <br /> {abbreviateNumber(item?.size)}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)},memoCompare);


export default RenderMatchItem;
