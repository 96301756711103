//events.js

function subscribe(eventName, listener) {
    document.addEventListener(eventName, listener);
}
  
function unsubscribe(eventName, listener) {
    document.removeEventListener(eventName, listener);
}
  
function emit(eventName, data) {
    const event = new CustomEvent(eventName, { data: data });
    document.dispatchEvent(event);
}
  
export { emit, subscribe, unsubscribe};