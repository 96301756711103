import React, { useState, useEffect } from 'react'
import { POST } from 'config/api';
import { truncate } from 'config/helper';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';

function Statements(props) {
    library.add(faCircle);
    const [historyList,setHistoryList] = useState([]);

    useEffect(()=>{
        getBattingHistory()
    },[])
    const getBattingHistory = () => {
        let body = {
            index:0,
            limit:10,
            type:'all'
        }
        POST(`${global.W_BASEURL}customer/transaction/getBattingHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setHistoryList(data?.list)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }
    // getBattingHistory

    return (
        <div className="col-lg-10">
            <div className="liveGame-section pageClass ">
                {/* <div className="liveGame-titleBox pageClass">
                    <div className="siteTitle pageClass pb-0">
                        <h2>Match Bet</h2>
                    </div>
                </div>
                <table className="table table-dark matchBet-table font-14 ">
                    <thead>
                        <tr style={{textAlign:'center'}}>
                            <th>S/N</th>
                            <th>Series</th>
                            <th>Match</th>
                            <th>Team</th>
                            <th>Bat Type</th>
                            <th>Stack</th>
                            <th>Bat Format</th>
                            <th>Result</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {historyList?.map((item,index)=>(
                        <tr style={{textAlign:'center'}}>
                            <td style={{'width':'50px'}}>{index + 1}</td>
                            <td>{truncate(item.series_name,25)}</td>
                            <td>{truncate(item.match_name,25)}</td>
                            <td>{(item.team_name)?item.team_name:'-'}</td>
                            <td style={{color:(item.bat_type == 'back')?'#72bbef':'#faa9ba'}}>{item.bat_type}</td>
                            <td>{item.bat_stack}</td>
                            <td>{(item.bat_format == 'normal')?'ODDS':'Fansy'}</td>
                            <td>{(item.result)?item.result:'Pending'}</td>
                            <td>{moment(item.create_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                        </tr>
                    ))}
                        
                        {(historyList?.length == 0)&&
                        <tr>
                            <td colSpan="9" className='text-center'>No Record Found!</td>
                        </tr>
                        }
                    </tbody>
                </table> */}
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Event Name</th>
                                <th>Team Name</th>
                                <th>Type</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>PNL</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        {historyList?.map((item,index)=>(
                            <tr className={`${(item?.bat_type == 'back')?'backRow':'layRow'}`}>
                                <td>{index + 1}</td>
                                <td>{truncate(item.series_name,25)}</td>
                                <td>{truncate(item.team_name,25)}</td>
                                <td>{(item.bat_format == 'normal')?'Match Odds':(item.bat_format == 'bookmaker')?'BookMaker':'Fansy'}</td>
                                <td>{item.bat_rate}</td>
                                <td>{item.bat_stack}</td>
                                <td>{(item.result)?item.result:'Pending'}</td>
                                <td>{moment(item.create_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                            </tr>
                        ))}
                            
                            {(historyList?.length == 0)&&
                            <tr>
                                <td colSpan="9" className='text-center'>No Record Found!</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                
            </div> 
        </div>
    );

}
export default Statements;