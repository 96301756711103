import React from 'react'

function AAA({dataList={},suspended=true,onCLick=()=>{}}) {

    
    const handleOnCLick = (data,bat_type) =>{
        if(!suspended){
            let rate = (bat_type == 'lay')?data?.l1:data?.b1;
            
            let nation = (data?.nat)?data?.nat:data?.nation;
            let batitem = {
                bat_rate:rate,
                bat_type:(bat_type)?bat_type:nation,
                sid:data?.sid,
            }

            onCLick(batitem);
        }
    }

    return (
        
        <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>
            <div className="topnewBet-box">
                <div className='col-md-12 row'>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. Amar</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.amar,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.amar?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.amar,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.amar?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. Akbar</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.akbar,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.akbar?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.akbar,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.akbar?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. Anthony</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.anthony ,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.anthony?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.anthony ,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.anthony?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="topnewBet-box">
                <div className='col-md-12 row'>
                    <div className="col-lg-4">
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.even)
                                }}>
                                    <h6 className="innerNewBat-box">{dataList?.even?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Even
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.odd)
                                }}>
                                    <h6 className="innerNewBat-box">{dataList?.odd?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Odd
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.red)
                                }}>
                                    <h6 className="innerNewBat-box">{dataList?.red?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src='/assets/images/casino/heart.png' style={{height:'25px',width:'25px'}} />
                                        <img src='/assets/images/casino/diamond.png' style={{height:'25px',width:'25px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.black)
                                }}>
                                    <h6 className="innerNewBat-box">{dataList?.black?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src='/assets/images/casino/spade.png' style={{height:'25px',width:'25px'}} />
                                        <img src='/assets/images/casino/club.png' style={{height:'25px',width:'25px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.under_7)
                                }}>
                                    <h6 className="innerNewBat-box">{dataList?.under_7?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Under 7
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.over_7)
                                }}>
                                    <h6 className='innerNewBat-box'>{dataList?.over_7?.b1}</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Over 7
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="topnewBet-bottomBox">
                <div className="row">
                    <div className="twoColum-box">
                        {/* <h5>12</h5> */}
                        <ul className="twoColum-groupList" style={{display:'flex'}}>
                            {['a',2,3,4,5,6,7,8,9,10,'j','q','k']?.map((v,i)=>(
                                <li onClick={()=>{
                                    handleOnCLick(dataList?.[`card_${v}`])
                                }}><img src={`/assets/images/casino/${v}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default AAA;