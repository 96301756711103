import React, { memo, useEffect, useRef, useState } from 'react'
import {POST,GET} from 'config/api';
import global from 'config/global';
import {useDispatch,useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {toast } from 'react-toastify';
// import Loader from 'components/loader/loader';
import { abbreviateNumber, updateExposure, updateUserWallet } from 'config/helper';
import { emit } from 'config/event';


const batModalEqual = (prevProps, nextProps) => {
    const { showBatModal,liveBhav } = nextProps;
    const { showBatModal: prevshowBatModal,liveBhav:prevliveBhav,modalItem  } = prevProps;

    /*if the props are equal, it won't update*/
    if(showBatModal && modalItem?.runner_id != undefined) {
        let runner_id = modalItem?.runner_id;
        const showBatModalEqual = (liveBhav[runner_id]?.back?.price == prevliveBhav[runner_id]?.back?.price || liveBhav[runner_id]?.lay?.price == prevliveBhav[runner_id]?.lay?.price) ;
        return showBatModalEqual;
        // return (showBatModal == prevshowBatModal);
    } else {
        return (showBatModal == prevshowBatModal);
    }
};
    

const RenderBatModal = memo(({showBatModal,liveBhav={},modalItem={} ,onClose}) =>{
    const {buyerdata,STAKELIST} = useSelector(state => state.buyerAuth);
    const liveBatRate = useRef({});
    const [delayLoader,setDelayLoader] = useState(false)
    
    
    let bhavback = liveBhav?.[modalItem?.runner_id]?.back;
    let bhavlay = liveBhav?.[modalItem?.runner_id]?.lay;
    
    if(modalItem?.fansy_id) {
        bhavback = liveBhav?.[modalItem?.fansy_id]?.back;
        bhavlay = liveBhav?.[modalItem?.fansy_id]?.lay;
    }

    useEffect(()=>{
        let data = (modalItem.battype == 'back')?bhavback:bhavlay;
        liveBatRate.current = data;
    },[liveBhav])

    const calculatePNL = (selectionId,bat_format) =>{
        let runner_id = selectionId;

        let rate = parseFloat(modalItem?.batRate);
        let price = parseFloat(stack);
        
        let bat_type = modalItem?.battype;
        // let exposure_item = (exposureData?.exposure_obj && Object.keys(exposureData?.exposure_obj)?.length)?exposureData?.exposure_obj:{};
        // let oldExposureValue = (exposure_item[runner_id])?parseFloat(exposure_item[runner_id]):0

        if(price) {

            if(runner_id == modalItem?.runner_id) {
                let profit = Math.round((rate * price) - price)

                if(bat_type == 'lay') {
                    // profit = -profit + oldExposureValue;
                    profit = -profit;
                    return profit.toFixed(2);
                } else {
                    // profit = profit + oldExposureValue;
                    profit = profit;
                    return profit.toFixed(2);
                }
            } else {
                if(bat_type == 'lay') {
                    // price = price + oldExposureValue;
                    price = price;
                    return price.toFixed(2);
                } else {
                    // price = price - oldExposureValue;
                    price = price;
                    return -price.toFixed(2);;
                }
            }
        } else {
            return 0;
        }
    }
    
    const batPlace = (v) =>{
        if(!isNaN(parseFloat(v.stack)) && !isNaN(parseFloat(v.batRate))) {

            let body = {
                team_name:v.runnerName,
                team_id:v.runner_id, 
                bat_type:v.battype, 
                bat_rate:v.batRate, 
                bat_size:v.batSize,
                bat_format:v.bat_format, 
                bat_stack:v.stack,
                fansy_id:v.fansy_id, 
                match_id:v?.match_id,
                marketId:v?.marketId,
                series_id:v?.series_id,
                user_id:buyerdata.id,
                user_parent_id:buyerdata.parent_id,
                user_type:'customer',
                exposure_bal:0,
                exposure_obj:{}
            }
            

            POST(`${global.W_BASEURL}customer/bat/batplace`,body).then((res) => {
                if(res.data.status == 200){
                    toast.info("Bat Placed Successfully",{autoClose: 2000});
                    refreshExposure();
                    onClose('batPlaced')
                }else{
                    if(res.data.msg) {
                        toast.info(res.data.msg,{autoClose: 2000});
                        onClose()
                    }
                }
            }).catch((err)=>{
                console.log(err,'////////////');
                onClose()

            })
        } else {
            toast.info("Please Enter Stack",{autoClose: 2000});
        }
    }

    const refreshExposure = (exposureList) =>{
        emit('refreshExposure',exposureList);
        emit('refreshBatHistory',true);
    }

    const [stack,setstack] = useState(0);
    
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const placeBatDelay = async(v) =>{
        if(v.bat_format == 'fansy' && v.stack < v.min){
            toast.info(`Mimimum Stack Price is ${v.min}`,{autoClose: 2000});
            return;
        } else if(v.bat_format == 'fansy' && v.stack > v.max){
            toast.info(`Maximum Stack Price is ${v.max}`,{autoClose: 2000});
            return;
        } else if(v.stack <= 0){
            toast.info(`Please Enter Stake Value`,{autoClose: 2000});
            return;
        } else {
            
            setDelayLoader(true);

            let delayTime = (global.delayTime)?global.delayTime:4000;
            await delay(delayTime);

            setDelayLoader(false);
            if(liveBatRate?.current?.price == 0 || liveBatRate?.current?.status == 'SUSPENDED' || liveBatRate?.current?.status == 'Ball Running'){
                toast.info(`Bat Suspended`,{autoClose: 2000});
                onClose();
                return;
            }
            else if(v.batRate != liveBatRate?.current?.price) {
                toast.info(`Bat Odds Change`,{autoClose: 2000});
                onClose();
                return;
            } else {
                batPlace(v)
                setstack('');
            }

        }
        
    }
    return (
        <Modal show={showBatModal} backdrop="static" dialogClassName="loginModal-box modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body betModal-box">
                            <button onClick={()=>{
                                onClose()
                            }} type="button" className="btn-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
                            <div className="betModal-title pageClass">
                                <div className="row">
                                    <div className="col align-self-center">
                                        <h4>{modalItem?.runnerName}</h4>
                                        <h6>{(modalItem?.battype == 'back')?'Back':'Lay'} (Bet For)</h6>
                                    </div>
                                    {(modalItem?.bat_format == 'fansy')?
                                        <div className="col-auto">
                                            <div className="row gx-2">
                                                <div className="col">
                                                    <div className="betView-box">
                                                        <h5>{bhavlay?.price}</h5>
                                                        <h6>{abbreviateNumber(bhavlay?.size)}</h6>
                                                    </div>
                                                </div>
                                                
                                                <div className="col">
                                                    <div className="betView-box">
                                                        <h5>{bhavback?.price} </h5>
                                                        <h6>{abbreviateNumber(bhavback?.size)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-auto">
                                            <div className="row gx-2">
                                                <div className="col">
                                                    <div className="betView-box">
                                                        <h5>{bhavback?.price} </h5>
                                                        <h6>{abbreviateNumber(bhavback?.size)}</h6>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="betView-box">
                                                        <h5>{bhavlay?.price}</h5>
                                                        <h6>{abbreviateNumber(bhavlay?.size)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="placeBat-section pageClass">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <h6>Rate</h6>
                                            <input readOnly value={modalItem?.batRate} disabled type="text" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <h6>Stake</h6>
                                            <input type="text" value={stack} onChange={({target})=>{
                                                const re = /^[0-9\b]+$/;
                                                if (target.value === '' || re.test(target.value)) {
                                                    setstack(target.value)
                                                }
                                            }} className="form-control" placeholder="Enter Stake" />
                                        </div>
                                    </div>
                                </div>
                                <ul className="quickBet-fild">
                                {STAKELIST?.map((v,i)=>(
                                    <li onClick={()=>{
                                        let value = stack + parseFloat(v?.val)
                                        setstack(value)
                                    }}><a href="javascript:void(0);">{v?.key}</a></li>
                                ))}
                                </ul>
                                {(delayLoader)?
                                    <div className="modal-footer spinerbg" style={{justifyContent:'center'}}>
                                        <div className="">
                                                Please Wait
                                                <img src='/assets/images/spin.gif' style={{height:'20px',width:'20px'}} />
                                        </div>
                                    </div> 
                                :
                                    <div className="row gx-2 justify-content-center">
                                        <div onClick={()=>{
                                                onClose(false)
                                                setstack('')
                                            }} className="col-auto">
                                            <div className="link-btn"><a  data-bs-dismiss="modal">Cancel</a></div>
                                        </div>
                                        <div onClick={()=>{
                                                placeBatDelay({stack,...modalItem})
                                            }} className="col-auto">
                                            <div className="link-btn1"><a href="javascript:void(0);">Place Bet</a></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="moreBet-option pageClass">
                                <table className="table table-dark table-striped matchBet-table">
                                {modalItem?.runnerList?.map((value)=>(
                                    <tr>
                                        <th>{value.team_name}</th>
                                        <td style={{color:(calculatePNL(value?.sid,modalItem?.bat_format) < 0)?'red':'white'}}>{calculatePNL(value?.sid,modalItem?.bat_format)}</td>
                                    </tr>
                                ))}
                                </table>
                            </div>
                        </div>
                    </div>
        </Modal>
    )
    
});
    export default RenderBatModal;