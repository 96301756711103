import React from 'react'

function AAA({dataList={},suspended=true,onCLick=()=>{}}) {

    
    const handleOnCLick = (data,bat_type) =>{
        let rate = (bat_type == 'lay')?data?.l1:data?.b1;
        
        let nation = (data?.nat)?data?.nat:data?.nation;
        let batitem = {
            bat_rate:rate,
            bat_type:(bat_type)?bat_type:nation,
            sid:data?.sid,
        }
        onCLick(batitem);
    }


    return (
        
        <div className="newBet-wrapper" style={{"backgroundColor":"#00676c"}}>
            <div className="topnewBet-box">
                <div className='col-md-12 row'>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. DON</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.don,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.don?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.don,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.don?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. Amar Akbar Anthony</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.amar_akbar_anthony,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.amar_akbar_anthony?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.amar_akbar_anthony,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.amar_akbar_anthony?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <h5 className="subBet-title">A. Sahib Bibi Aur Ghulam</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.sahib_bibi_aur_ghulam ,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.sahib_bibi_aur_ghulam?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.sahib_bibi_aur_ghulam ,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.sahib_bibi_aur_ghulam?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 row'>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">D.Dharam Veer</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.dharam_veer,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.dharam_veer?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.dharam_veer,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.dharam_veer?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">E.Kis Kis Ko Pyaar Karoon</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.kis_kisko_pyaar_karoon,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.kis_kisko_pyaar_karoon?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.kis_kisko_pyaar_karoon,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.kis_kisko_pyaar_karoon?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="subBet-title">F.Ghulam</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.ghulam ,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.ghulam?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.ghulam ,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.ghulam?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="topnewBet-bottomBox">
                <div className="row">
                    <div className="col-lg-4">
                        <h5 className="subBet-title">ODD</h5>
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.odd,'back')
                                }}>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.odd?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.odd,'lay')
                                }}>
                                    <div className={`newBat-viewBox pageClass PinkPrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.odd?.l1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.dulha_dulhan_k_q)
                                }}>
                                    <h6 className='subBet-title'>Dulha Dulhan K-Q </h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.dulha_dulhan_k_q?.b1}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6 mb-2">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    (!suspended) && handleOnCLick(dataList?.barati_j_a)
                                }}>
                                    <h6 className='subBet-title'>Barati J-A</h6>
                                    <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        {dataList?.barati_j_a?.b1}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 row">
                        <div className="col-sm-6 col-6 mb-2">
                            <h5 className="subBet-title">Red</h5>
                            <div className="innerNewBat-box pageClass" onClick={()=>{
                                (!suspended) && handleOnCLick(dataList?.red)
                            }}>
                                <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                    {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                    {dataList?.red?.b1}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-6 mb-2">
                            <h5 className="subBet-title">Black</h5>
                        <div className="innerNewBat-box pageClass" onClick={()=>{
                            (!suspended) && handleOnCLick(dataList?.black)
                        }}>
                            <div className={`newBat-viewBox pageClass bluePrice ${(suspended)&& 'btnOverlay1'}`}>
                                {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                {dataList?.black?.l1}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="twoColum-box">
                                <h5></h5>
                                <ul className="twoColum-groupList" style={{display:'flex'}}>
                                    {['j','q','k','a']?.map((v,i)=>(
                                        <li onClick={()=>{
                                            (!suspended) && handleOnCLick(dataList?.[`card_${v}`])
                                        }}><img src={`/assets/images/casino/${v}.png`} style={{marginBottom:'10px',marginRight:'10px'}} /></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default AAA;