import React, { useEffect, useState } from "react";

const CardSlider = ({ data=[],style,defaultLength=3,type='short' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(()=>{
    let lengthCount = (defaultLength)?defaultLength:(data?.length >= 3 )? 3 : data?.length;
    if(data?.length - lengthCount != currentIndex){
        setCurrentIndex(data?.length - lengthCount)
    }
  },[data])
  // Navigate to the next set of cards
  const goNext = () => {
    if (currentIndex + defaultLength < data.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Navigate to the previous set of cards
  const goPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Determine the visible cards based on currentIndex
  let visibleCards = data.slice(currentIndex, currentIndex + defaultLength);
  
  if(visibleCards?.length){
    return (
        <div style={{display:'flex',marginBottom:'10px',justifyContent:'center',alignItems:'center',...style}}>
                <div style={{padding:'7px'}} disabled={currentIndex === 0} onClick={goPrev} >
                    <i style={{color:(data?.length <= defaultLength || currentIndex === 0)?'#525056':'#FFFFFF',fontSize:'30px',marginRight:'10px'}} className="fa fa-angle-left" />
                </div>
                {visibleCards.map((card, index) => (
                    <img  src={`/assets/images/casino/${card}.png`} className={`${(type=='full')?'blank-flip-image2':'blank-flip-image'} loaded`} loading="lazy"/>
                ))}
                <div style={{padding:'7px'}} onClick={goNext} >
                    <i style={{color:(currentIndex + defaultLength >= data.length)?'#525056':'#FFFFFF',fontSize:'30px',marginLeft:'10px'}} className="fa fa-angle-right" />
                </div>
        </div>
      );
  } else {
    return null;
  }
  
};

export default CardSlider;
