import React, { useState, useEffect } from 'react'
import Global from '../../../config/global';
import Slider from 'react-slick';


function HomeSlider({List=[]}) {

	const [loading, setLoading] = useState(true);

	useEffect(() => {
        // Simulate data loading
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust as needed
        return () => clearTimeout(timer);
    }, []);

	const settings_header_slider = {
		dots: false,
		infinite: true,
		autoplay: true,
        arrows: false,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		
	};

	return (
		<div className="homeBanner pageClass" >
            {loading ? (
                <div className="skeleton-container">
                    <div className="skeleton-banner"></div>
                </div>
            ) : (
                <Slider className="homeBannerSlider" {...settings_header_slider}>
                    {List.map((value, index) => (
                        <div className="homeBannerSlide" key={index}>
                            <div className="homeBanner-pic pageClass">
                                <img
                                    src={`${Global.IMAGE_PATH}${value}`}
                                    className="w-100"
                                    alt={`Banner ${index}`}
                                    loading="lazy"
                                    style={{objectFit: "cover" }}
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
	);

}

export default HomeSlider;
