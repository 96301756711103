import { useSelector } from 'react-redux';

const Auth = () =>{
    const data = useSelector(state=>state.buyerAuth);
    if(Object.keys(data?.buyerdata).length > 0 && data?.buyerdata?.id !== '')
    {
        return true;
    }
    else
    {
        return false;
    }
}

const Middleware = {
    Auth : Auth
}
export {Middleware};