import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { disconnectSocket, getSocket, initSocket } from 'config/socket';
import { useDispatch, useSelector } from 'react-redux';
import { POST } from 'config/api';
import global from 'config/global';



const OddBatOuter = memo(({match_id,key,ishome}) => {
    const oldOdds = useRef(null);
    const [marketRunner,setMatketRunner] = useState({});

    const isPageLoaded = useRef();
 
    useEffect(()=>{
       
        if(match_id) {
            if(ishome != true) {
                fetchSocketMarketRunner(match_id)
            } else {
                fetchRestApiMarketRunner(match_id)
            }
        }        
    },[]);

    const fetchRestApiMarketRunner = (match_id) =>{
        let body = {
            match_id:match_id,
        }

        POST(`${global.MatchAPI}thirdPartyApi/fetchOdds`,body).then((res) => {
            if(res.data.status == 200){
                let response = res.data?.data;
                if(response) {
                    let matchOdds = checkValueIsChange(response?.odds);
                    setMatketRunner(matchOdds)
            }
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const fetchSocketMarketRunner = (match_id) =>{

        if(isPageLoaded.current == undefined) {
            isPageLoaded.current = true;
            let socket = getSocket();
            
            if(socket?.connected){
                socket?.on('sendOuterOdds', (data)=>{
                    if(data) {
                        let matchData = JSON.parse(data);
                        if(matchData[match_id])
                        {
                            let matchOdds = matchData[match_id]?.odds
                            let newmatchData = checkValueIsChange(matchOdds);
                            setMatketRunner(newmatchData)
                        }
                    }
                });
            }
        }
    }

    const checkValueIsChange = (marketOdds) =>{
        let outerData = {};

        let i = 0;
        for(let item of marketOdds){
            let back_index = 0;
            let lay_index = 0;

            for(let item2 of item?.back_odds) {
                if(back_index == 0) {
                    let key = `back${i}`;
                    if(oldOdds?.current && oldOdds?.current?.[key] != item2.price){
                        outerData[`${key}_update`] = true;    
                    } else {
                        outerData[`${key}_update`] = false;    
                    }

                    outerData[key] = item2.price;
                    break;
                }
                back_index = back_index+1;
            }
            for(let item2 of item?.lay_odds) {
                if(lay_index == 0) {
                    let key = `lay${i}`;
                    
                    if(oldOdds?.current && oldOdds?.current?.[key] != item2.price){
                        outerData[`${key}_update`] = true;    
                    } else {
                        outerData[`${key}_update`] = false;    
                    }

                    outerData[key] = item2.price;
                    break;
                }
                lay_index = lay_index+1;
            }

            i++;
        }

        oldOdds.current = outerData;
        return outerData;
    }
   
    return <RenderMatchItem key={key} marketRunner={marketRunner} />
})


const memoCompare = (prevProps, nextProps) =>{
    if(
        prevProps?.marketRunner?.back0 != nextProps?.marketRunner?.back0 || prevProps?.marketRunner?.lay0 != nextProps?.marketRunner?.lay0 ||
        prevProps?.marketRunner?.back1 != nextProps?.marketRunner?.back1 || prevProps?.marketRunner?.lay1 != nextProps?.marketRunner?.lay1 ||
        prevProps?.marketRunner?.back2 != nextProps?.marketRunner?.back2 || prevProps?.marketRunner?.lay2 != nextProps?.marketRunner?.lay2
    ) {
        return false;
    } else {
        return true;
    }
}

const RenderMatchItem = memo(({marketRunner={}}) =>{
    return (
        <>
        <div className="row gx-2">
            <div className="col">
                <div className="priceColum-row">
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`bluePrice ${(marketRunner?.back0_update == true ) ? 'colorBlinkBack':''}`}>{(marketRunner?.back0)?marketRunner?.back0:'--'}</a>
                    </div>
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`PinkPrice ${(marketRunner?.lay0_update == true ) ? 'colorBlinkLay':''}`}>{(marketRunner?.lay0)?marketRunner?.lay0:'--'}</a>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="priceColum-row">
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`bluePrice ${(marketRunner?.back2_update == true ) ? 'colorBlinkBack':''}`}>{(marketRunner?.back2)?marketRunner?.back2:'--'}</a>
                    </div>
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`PinkPrice ${(marketRunner?.lay2_update == true ) ? 'colorBlinkLay':''}`}>{(marketRunner?.lay2)?marketRunner?.lay2:'--'}</a>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="priceColum-row">
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`bluePrice ${(marketRunner?.back1_update == true ) ? 'colorBlinkBack':''}`}>{(marketRunner?.back1)?marketRunner?.back1:'--'}</a>
                    </div>
                    <div className={`priceColum-grid outer-bat`}>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#betModal" className={`PinkPrice ${(marketRunner?.lay1_update == true ) ? 'colorBlinkLay':''}`}>{(marketRunner?.lay1)?marketRunner?.lay1:'--'}</a>
                    </div>
                </div>
            </div>
        </div>
        </>
)},memoCompare);



export default OddBatOuter
