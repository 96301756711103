import React, { useState, useEffect, memo } from 'react'
import Axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.css';
import Global from '../../../config/global';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { BuyerLogin } from './../../../redux/buyer/action';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import NavBarPanel from './navBarPanel';
// import RightPanel from './rightPanel';
import { emit, subscribe, unsubscribe } from 'config/event';
import RightPanel from './rightPanel';
import Marquee from './Marquee';
import { GET, POST } from 'config/api';
import firebaseNotification from 'config/firebase';


const Header = () => {

	const [showLoginModal, setLoginModal] = useState(false);
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const [state, setState] = useState({
		isLoading: true,
		headerBanner: ["header_banner1.jpg", "header_banner2.jpg"],
		sitedata: {'logo':'1731431043520.webp'}
	});

	  
	let interval = ''
	useEffect(() => {
		fetchLogo();

		subscribe('showLoginBatModal', (data) => {
			setLoginModal(true)
		});


		if (buyerdata?.token) {
			interval = setInterval(() => {
				fetchCustomerData()
			}, 10000);
		}


		return () => {
			if (buyerdata?.token) {
				clearInterval(interval)
			}
			unsubscribe('showLoginBatModal');
		}

	}, [buyerdata?.id]);

	const fetchCustomerData = async () => {
		GET(`${Global.W_BASEURL}customer/transaction/fetchCustomerData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				let oldData = { ...buyerdata };

				oldData.rel_wallet = (data?.wallet != '') ? data?.wallet : 0;
				oldData.wallet = (data?.wallet != '') ? data?.wallet : 0;
				oldData.exposure_bal = (data?.exposure_bal) ? data?.exposure_bal : 0;

				dispatch({ type: 'BLOGIN', payload: oldData });
			}
		})
	}
	const fetchLogo = async () => {
		GET(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("isLoading", false);
				stateHandler("sitedata", data.sitedata);
			}
		})
	}

	const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}

	return (
		<>
		<RenderLoginModal dispatch={dispatch} navigate={navigate} logo={`${Global.LOGO_PATH}${state?.sitedata?.logo}`} showLoginModal={showLoginModal} onClose={setLoginModal} />
		<div className="fixed-header">
			<header id="pageHeaderWrapper" >
			<Marquee marquee={state?.sitedata?.dashboard_marque} />

					<div id="pageHeader">
						<div className="header-container">
							<div className="topHeaderContainer">
								<div className="logo">
									<a href="/" title="vasudevexchange">
										<img src={`${Global.LOGO_PATH}${state?.sitedata?.logo}`} alt="vasudevexchange" />
									</a>
								</div>
								<div className="topRightHeader">
									<div className="nav-wrapper">
										<div className="nav-container">
											<nav className="nav">
												<ul className="main-menu">
													<li className="active"><Link to={'/'}><span>Home</span></Link></li>
													<li><Link to={'/sports'}><span>SPORTS</span></Link></li>
													<li><Link to={'/livecasino'}><span>LIVE CASINO</span></Link></li>
													<li><Link to={'/'}><span>VIRTUAL SPORTS</span></Link></li>
													<li><Link to={'/'}><span>SPECIAL MARKET</span></Link></li>
												</ul>
											</nav>
										</div>
									</div>
									{(!buyerdata?.id) ?
										<div className="headerBtns-group">
											<div className="row">
												<div className="col-auto" onClick={() => {
													setLoginModal(true);
												}}>
													<div className="link-btn"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#loginModal"><i className="fa-regular fa-user"></i> Login</a></div>
												</div>
												<div className="col-auto">
													<div className="link-btn1"><Link to={'/signup'}><i className="fa-solid fa-right-to-bracket"></i> Sign Up</Link></div>
												</div>
											</div>
										</div>
										:
										<div className="headerInnerBtns-group">
											<div className="row align-items-center">
												
												<RightPanel />
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
			</header>
			</div>
			{/* <!--Mobile Nav--> */}
			<div className="mobileHeader-links">
				{(buyerdata?.token) ?
					<ul className="headerTopLinks">
						<li><Link to={'/'}><img src="/assets/images/mobileMenu-1.png" alt="Vasudev" /> <h6>Home</h6></Link></li>
						<li><Link to={'/'}><img src="/assets/images/mobileMenu-3.png" alt="Vasudev" /> <h6>InPlay</h6></Link></li>
						<li><Link to={'/bat_history'}><img src="/assets/images/mobileMenu-2.png" alt="Vasudev" /> <h6>Bat History</h6></Link></li>
						<li><Link to={'/deposit'}><img src="/assets/images/mobileMenu-4.png" alt="Vasudev" /> <h6>Deposit</h6></Link></li>
						<li onClick={() => {
							emit('openSideBar')
						}}><a><img src="/assets/images/mobileMenu-5.png" alt="Vasudev" /> <h6>Profile</h6></a></li>
					</ul>
					:
					<ul className="headerTopLinks">
						<li><Link to={'/'}><img src="/assets/images/mobileMenu-1.png" alt="Vasudev" /> <h6>Home</h6></Link></li>
						<li><Link to={'/game/4'}><img src="/assets/images/cricket-bw.png" alt="Vasudev" /> <h6>Cricket</h6></Link></li>
						<li><Link to={'/game/1'}><img src="/assets/images/football-bw.png" alt="Vasudev" /> <h6>Soccer</h6></Link></li>
						<li><Link to={'/game/2'}><img src="/assets/images/tennis-bw.png" alt="Vasudev" /> <h6>Tennis</h6></Link></li>
						<li onClick={() => {
							setLoginModal(true)
						}}><a><img src="/assets/images/mobileMenu-5.png" alt="Vasudev" /> <h6>Login</h6></a></li>
					</ul>
				}
			</div>
		</>
	);

}


const memoCompare = (prevProps, nextProps) => {
	const { showLoginModal } = nextProps;
	const { showLoginModal: prevshowLoginModal } = prevProps;
	return (showLoginModal == prevshowLoginModal);
}


const RenderLoginModal = memo(({showLoginModal,dispatch,onClose,navigate}) =>{
	const [username, setUsername] = useState('');
  	const [password, setPassword] = useState('');
	const [isLoading,setIsLoading] = useState(false);
	
	const fetchUser = async (username, password, loginType) => {
		let fcmToken = await firebaseNotification.getToken();
		
		if (loginType == 'Demo') {
			username = 'demo@gmail.com';
			password = 'password';
		}
		if (username == '') {
			toast.error("Please Enter UserName", { autoClose: 2000 });
		}
		else if (password == '') {
			toast.error("Please Enter Password", { autoClose: 2000 });
		} else {
			let body = {
				username: username,
				password: password,
				fcm_token: fcmToken
			};

			setIsLoading(true);

			dispatch(BuyerLogin(body)).then((res) => {
				setIsLoading(true);
				onClose(false);

				if (res.status == 200) {
					if(res.first_time_pass_change=='true'){
						 navigate('/dashboard');
					}else{
						 navigate('/change_password');
					}
					// navigate('/dashboard');
				}
				else {
					toast.error("Invalid Username Or Password", { autoClose: 2000 });
				}
				setIsLoading(false);
			});
		}

	}

	return (
		<Modal show={showLoginModal} dialogClassName="loginModal-box modal-dialog modal-xl modal-dialog-centered" onHide={onClose}>
			<div className="">
				<div className="modal-body">
					<button type="button" onClick={() => {
						onClose()
					}} className="btn-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
					<div className="row">
						<div className="col-lg-6  align-self-center">
							<div className="loginWrapper-box">
								<div className="loginTitle pageClass">
									<h2>Welcome To <strong>VASUDEV EXCHANGE</strong></h2>
								</div>
								<div className="loginMob-logo pageClass">
									<img src="/assets/images/fullLogo.png" alt="Vasudev" />
								</div>
								<div className="loginFrom-wrapper pageClass">
									<div className="form-group">
										<label className="control-label">E-mail/UserName <span className="required-field">*</span></label>
										<input type="text" className="form-control" onChange={({ target }) => {
											setUsername(target.value)
										}} placeholder="demo@gmail.com" />
									</div>
									<div className="form-group">
										<label className="control-label">Password <span className="required-field">*</span></label>
										<input type="text" onChange={({ target }) => {
											setPassword(target.value)
										}} className="form-control" placeholder="Enter Password" />
									</div>
									<div className="loginFrom-wrapper pageClass">
										<div className="form-group">
											<div className="form-group form-check">
												<input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
												<label className="form-check-label" htmlFor="check1">I understand the risks of discontinuing maintenance and agree to proceed.</label>
											</div>                                     
										</div>
										<div className="form-group pt-3">
											{(isLoading)?
												<div type="submit" className="btn btn-primary">Loading, please wait...</div>
											:
												<button type="submit" onClick={()=>{
													fetchUser(username,password,'Login')
												}} className="btn btn-primary"><i className="fa-solid fa-right-to-bracket"></i> Login</button>
											}
											
										</div>
										{(isLoading)?
												<div className="form-group demoLogin-btn text-center">
													<a href="javascript:void(0);">Loading, please wait...</a>
												</div>
											:
											<div onClick={()=>{
												fetchUser('demo@gmail.com','password','Demo')
											}} className="form-group demoLogin-btn text-center">
												<a href="javascript:void(0);">Demo Login</a>
											</div>
										}
										
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="loginBg-wrapper">
								<img src="/assets/images/loginBg.png" className="w-100" alt="Vasudev" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}, memoCompare);


export default Header;
