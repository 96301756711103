import React from 'react'

function AndarBahar2({dataList={},suspended=true,onCLick=()=>{}}) {

    
    const handleOnCLick = (data) =>{
        if(!suspended && data){
            let batitem = {
                bat_rate:data?.b1,
                bat_type:(data?.nation)?data?.nation:data.nat,
                sid:data?.sid,
            }
            onCLick(batitem);
        }
    }

    return (
        <div className="newBet-wrapper" style={{backgroundColor:'#00676c',padding:'10px',borderRadius:'5px'}}>
        <div className="topnewBet-box">
            <div className="row gx-3">
                <div className='col-lg-6' >
                    <div className='col-lg-12'>
                        <div className='mt-2' style={{textAlign:'center',color:'#FFF',fontSize:'20px'}}>
                            Andar
                        </div>
                        <div className='row' style={{marginTop:'15px',marginBottom:'15px'}}>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick(dataList?.sa)
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.sa?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        SA
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick({...dataList?.['1st_bet'],sid:"2"})
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.['1st_bet']?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        First Bet
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick({...dataList?.['2nd_bet'],sid:"3"})
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.['2nd_bet']?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Second Bet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="row gx-3">
                            <div className="col-sm-6 col-6">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_even)
                                }}>
                                    <h6>{dataList?.joker_even?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Even
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_odd)
                                }}>
                                    <h6>{dataList?.joker_odd?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Odd
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='col-lg-12'>
                        <div className='mt-2' style={{textAlign:'center',color:'#FFF',fontSize:'20px'}}>
                            Bahar
                        </div>
                        <div className='row' style={{marginTop:'15px',marginBottom:'15px'}}>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick(dataList?.sb)
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.sb?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        SB
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick({...dataList?.['1st_bet'],sid:"5"})
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.['1st_bet']?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        First Bet
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4">
                                <div onClick={()=>{
                                    handleOnCLick({...dataList?.['2nd_bet'],sid:"6"})
                                }} className="innerNewBat-box pageClass ">
                                    <h6>{dataList?.['2nd_bet']?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        Second Bet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="row gx-3">
                            <div className="col-sm-3 col-3">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_spade)
                                }}>
                                    <h6>{dataList?.joker_spade?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src={`/assets/images/casino/spade.png`} style={{height:'25px',width:'25px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-3">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_club)
                                }}>
                                    <h6>{dataList?.joker_club?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src={`/assets/images/casino/club.png`} style={{height:'25px',width:'25px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-3">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_heart)
                                }}>
                                    <h6>{dataList?.joker_heart?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src={`/assets/images/casino/heart.png`} style={{height:'25px',width:'25px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-3">
                                <div className="innerNewBat-box pageClass" onClick={()=>{
                                    handleOnCLick(dataList?.joker_diamond)
                                }}>
                                    <h6>{dataList?.joker_diamond?.b1}</h6>
                                    <div className={`newBat-viewBox bluePrice pageClass ${(suspended)&& 'btnOverlay1'}`}>
                                        {(suspended)&&<div className='overlayText' style={{color:'#FFFFFF'}}><i className='fa fa-lock' /></div>}
                                        <img src={`/assets/images/casino/diamond.png`} style={{height:'25px',width:'25px'}} />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="topnewBet-bottomBox" style={{marginTop:'30px'}}>
            <div className="twoColum-box">
                <div className="screenCard-list-new">
                    {['a',2,3,4,5,6,7,8,9,10,'j','q','k']?.map((v,i)=>(
                        <img onClick={()=>{
                            handleOnCLick(dataList?.[`joker_${v}`])
                        }} src={`/assets/images/casino/${v}.png`} />
                    ))}
                </div>
            </div>
        </div>
          </div>
    );

}
export default AndarBahar2;