import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import { GET, POST } from 'config/api';
import global from 'config/global';
import { useSelector } from 'react-redux';
import Header from 'component/web/includes/header';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function Deposit(props) {   
    const [depositStep,setDepositStep] = useState(1);
    const [depositAmount,setDepositAmount] = useState('');
    const [bankDetails,setBankDetails] = useState({});
    const [utr,setutr] = useState('');
    const [proof,setProof] = useState('');
    const [terms,setterms] = useState(false);
    const [activeAccount,setactiveAccount] = useState(0);
    const [bankList,setBankList] = useState([])
    const {buyerdata} = useSelector(state => state.buyerAuth);
	let navigate = useNavigate();


    useEffect(()=>{
        fetchBankAccountList();
    },[]);

    const fetchBankAccountList = () =>{
        let body = {
            parent_id : buyerdata.parent_id
        }

        POST(`${global.W_BASEURL}customer/transaction/fetchAccountList`,body).then((res) => {
            if(res.status == 200){
                if(res?.data?.data?.length > 0) {
                    let defaultData = res?.data?.data[0];
                    setBankDetails(defaultData);
                }
                setBankList(res?.data?.data);
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    const accountTitle = (item) =>{
        if(item?.payment_method == 'upi') {
            return item?.upi_platform;
        } else {
            return 'BANK';
        }
    }
    
    const validation = () =>{
        if(depositAmount =='' || isNaN(depositAmount)) {
            toast("Please Enter Deposite Amount");
            return false;
        }
        else if(utr == '') {
            toast("Please Enter UTR No");
            return false;
        } else if(proof == '') {
            toast("Please Upload Screenshot of Payment");
            return false;
        }else if(terms == false) {
            toast("Please Accept Terms & Condition");
            return false;
        }
        else {
            return true;   
        }
    }
    
    const uploadPaymentTransaction = () =>{
        if(validation()) {
            let body = {
                utr : utr,
                proof:proof,
                amount:depositAmount,
                parent_id:buyerdata?.parent_id,
            }
    
            POST(`${global.W_BASEURL}customer/transaction/uploadPayment`,body).then((res) => {
                if(res.status == 200){
                    toast("Deposite Request Successfully please Wait");
                    navigate('/');
                } else {
                    console.log(res.data)
                }
            }).catch((err)=>{
                console.log(err)
            })
        }
         
    }

    
    const getBase64 = (target)=>{
        if(target.files.length > 0)
        {
            let file = target.files[0];
            let types = ['image/png','image/jpeg','image/jpg','image/webp']
            
            if(types.indexOf(file.type) >= 0)
            {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    setProof(reader.result);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }
                
        }
    }
    

    const renderAccountDetails = () =>{
        if(bankDetails?.payment_method == 'bank_account') {
            return (
                <div className='bankDetails-infoBox'>
                    <div className='bankInnerDetails-col' style={{"display": "flex"}}>
                    <div className="wallet-info-title">Bank Name : </div>
                    <div className="wallet-info-detail"> {bankDetails?.bank_name}</div>
                    </div>
                    <div className='bankInnerDetails-col' style={{"display": "flex"}}>
                    <div className="wallet-info-title">A/C No : </div>
                    <div className="wallet-info-detail"> {bankDetails?.account_no}</div>
                    </div>
                    <div  className='bankInnerDetails-col' style={{"display": "flex"}}>
                    <div className="wallet-info-title">IFSC Code : </div>
                    <div className="wallet-info-detail"> {bankDetails?.ifsc}</div>
                    </div>
                    <div  className='bankInnerDetails-col' style={{"display": "flex"}}>
                    <div className="wallet-info-title">Account Name : </div>
                    <div className="wallet-info-detail"> {bankDetails?.account_name}</div>
                    </div>
                </div>
            )
        } else if(bankDetails?.payment_method == 'upi') {
            return (
                <img src={`${global.BANK_IMAGE_PATH}${bankDetails?.upi_qr}`} alt="" />
            )
        } else {
            return null;
        }
    }

    return (

    <div className="col-lg-10">
        <div className='pageRight-mainWrapper'>
            <div className="depositsWrapper">
                <h2>Deposit</h2>
                <ul className="depositsListing">
                    {bankList?.map((v,i)=>(
                        <li onClick={()=>{
                            setactiveAccount(i);
                            setBankDetails(v);
                        }} className={(activeAccount == i)?"active":''}><a >{accountTitle(v)}</a></li>
                    ))}
                </ul>

                <div className="depositAcoumt-box pageClass">
                    <div className="depositAmt-title pageClass"><h5>{(bankDetails?.upi_platform)?bankDetails?.upi_platform:bankDetails?.bank_name}</h5></div>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas molestias dolorem deserunt asperiores distinctio sapiente nemo repudiandae quam ab pariatur necessitatibus nisi ex, minima hic quia minus, illo illum! Rerum?</p> */}
                    <div className="row grid-15 pt-1">
                        <div className="col-lg-4 order-lg-2 align-self-center">
                            <div className="depositAmt-QR">
                                {renderAccountDetails()}
                            </div>
                        </div>
                        <div className="col-lg-8 order-lg-1">
                            <div className="depositAmt-form pageClass">
                                <div className="form-group">
                                    <label className="control-label">Amount</label>
                                    <input onChange={({target})=>{
                                            const re = /^[0-9\b]+$/;
                                            if (target.value === '' || re.test(target.value)) {
                                                setDepositAmount(target.value);
                                            }
                                            
                                        }} value={depositAmount} placeholder="Enter Amount" type="text" className="form-control" />
                                </div>
                                
                                <div className="form-group">
                                    <label className="control-label">UTR No.</label>
                                    <input onChange={({target})=>{
                                        const re = /^[0-9\b]+$/;
                                        if (target.value === '' || re.test(target.value)) {
                                            setutr(target.value);
                                        }
                                    }} value={utr} type="text" placeholder="10 Digit USDT Reference No" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Your Payment Proof</label>
                                    <input onChange={({target})=>{
                                            getBase64(target)
                                        }} type="file" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <div className="form-group form-check">
                                        <input onClick={()=>{
                                                setterms(!terms);
                                            }} className="form-check-input" type="checkbox" id="check1" name="option1" value={terms} />
                                        <label className="form-check-label" htmlFor="check1">I have read and agree with the terms of payment and withdrawal policy.</label>
                                    </div>                                     
                                </div>
                                <div className="form-group pt-3" >
                                    <button onClick={()=>{
                                        uploadPaymentTransaction();
                                    }} type="submit" className="btn btn-primary" style={{border:'0px',backgroundColor:'#0094a4'}}><i className="fa-solid fa-right-to-bracket"></i> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </div>                      
    </div>
    );

}
export default Deposit;